import cloneDeepWith from "lodash/cloneDeepWith";

/**
 * cloning an object with option to exclude keys
 *
 * @param collection
 * @param excludeKeys
 * @returns {*}
 */

function omitDeep(collection, excludeKeys) {

    function omitFn(value) {

        if (value && typeof value === "object") {
            excludeKeys.forEach((key) => {
                delete value[key];
            });
        }
    }

    return cloneDeepWith(collection, omitFn);
}

export default omitDeep;