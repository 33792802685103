import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    manageUserSaveEmail,
    manageUserSaveFirstname,
    manageUserSaveLastname,
    manageUserSaveOkComplyRole,
    manageUserSaveOkAuditRole,
    manageUserSaveOkPublishRole,
    manageUserSaveOrganisationRole,
    manageUserSaveReadPermissions,
    manageUserSaveWritePermissions,
    manageUserSetChecked
} from "redux/actions/manageUser";
import { okComplyRoles } from "constants/role";

export function useManageUser() {

    const dispatch = useDispatch();
    const { user, loading } = useSelector(state => state.manageUser);
    const {
        email,
        firstname,
        lastname,
        writePermissions,
        readPermissions,
        okComplyRole,
        okAuditRole,
        okPublishRole,
        organisationRole,
        checked,
        okauditChecked,
        okpublishChecked
    } = user;

    // only for okcomply: read and write permissions
    const onChangeWritePermissions = useCallback((currentNode, selectedNodes) => {
            dispatch(manageUserSaveWritePermissions(selectedNodes));
    }, [dispatch]);

    const onChangeReadPermissions = useCallback((currentNode, selectedNodes) => {
        dispatch(manageUserSaveReadPermissions(selectedNodes));
    }, [dispatch]);

    const onChangeOkComplyRole = useCallback((role) => {

        dispatch(manageUserSaveOkComplyRole(role));

        if (role === okComplyRoles.EXECUTOR) {
            dispatch(manageUserSaveReadPermissions([]));
        }
    }, [dispatch]);

    const onChangeOkAuditRole = (role) => {
        dispatch(manageUserSaveOkAuditRole(role));
    };

    const onChangeOkPublishRole = (role) => {
        dispatch(manageUserSaveOkPublishRole(role));
    };

    const onChangeOrganisationRole = (role) => {
        dispatch(manageUserSaveOrganisationRole(role));
    };

    const onChangeEmail = (event) => {
        const { value } = event.target;
        dispatch(manageUserSaveEmail(value));
    };

    const onChangeFirstname = (event) => {
        const { value } = event.target;
        dispatch(manageUserSaveFirstname(value));
    };

    const onChangeLastname = (event) => {
        const { value } = event.target;
        dispatch(manageUserSaveLastname(value));
    };

    const onChecked = (module, checked) => {
        dispatch(manageUserSetChecked(module, checked));
    };

    return {
        writePermissions,
        readPermissions,
        onChangeReadPermissions,
        onChangeWritePermissions,
        onChangeOrganisationRole,
        onChangeOkComplyRole,
        onChangeOkAuditRole,
        onChangeOkPublishRole,
        onChangeEmail,
        onChangeFirstname,
        onChangeLastname,
        onChecked,
        firstname,
        lastname,
        email,
        okComplyRole,
        okAuditRole,
        okPublishRole,
        organisationRole,
        manageUser: user,
        checked,
        loading,
        okauditChecked,
        okpublishChecked
    };

}