import client from "graphql/client";
import { loader } from "graphql.macro";
import approveInviteFunction from "./approveInviteFunction";
const approveInvite = loader("graphql/mutations/invites/approveInvite.graphql");

export default function approveInviteAction(organisationId, state) {
    return () => {
        const { inviteId, sequenceNumber } = state;
        const series = {
            id: inviteId,
            sequenceNumber: sequenceNumber + 1
        };

        return client.mutate({
            mutation: approveInvite,
            variables: {organisationId, series},
            update: approveInviteFunction(organisationId)
        });
    };
}
