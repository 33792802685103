import React from "react";
import OwnershipOverview from "./OwnershipOverview";

export default function ManagementOwnership() {
    return (
        <div className="users-flow">
            <OwnershipOverview/>
        </div>
    );
};
