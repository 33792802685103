import React, { useState, useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Icon, { ICONS } from "shared/components/Icon";
import { fetchUsersAction, fetchUserResponsibilitiesAction } from "redux/actions/manageUser";
import { transferRemoveUserResponsibility, tranferSaveUserResponsibilities } from "redux/actions/transfer";
import UserResponsibilityItem from "./UserResponsibilityItem";
import { VariableSizeList as List } from "react-window";
import "./style.scss";
import { useWindowSize } from "../../hooks/useWindowSize";

export const TransferContext = React.createContext({});
const TransferOverview = () => {

    const [t] = useTranslation();
    const dispatch = useDispatch();
    const { current: organisation } = useSelector(state => state.organisation);
    const { users } = useSelector(state => state.manageUser);
    const [expanded, setExpanded] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const { responsibilities } = useSelector(state => state.transferReducer);
    const [windowWidth, windowHeight] = useWindowSize();

    const listRef = useRef();

    const sizeMap = React.useRef({});
    const setSize = React.useCallback((index, size) => {
        sizeMap.current = { ...sizeMap.current, [index]: size + 10 };
        listRef.current.resetAfterIndex(0);
    }, []);
    const getSize = React.useCallback(index => sizeMap.current[index] || 150, []);

    const fetchUsers = useCallback(() => {
        if (organisation?.id) {
            dispatch(fetchUsersAction(organisation.id));
            dispatch(tranferSaveUserResponsibilities(null));
            setExpanded(false);
        }
    }, [dispatch, organisation]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    function updateSize() {
        const legends = document.getElementsByTagName("legend");
        for (let legend of legends) {
            const grandparentDiv = legend.parentElement.parentElement;
            const grandparentWidth = grandparentDiv.offsetWidth;
            legend.style.maxWidth = `${grandparentWidth - 10}px`;
        }
    }

    useLayoutEffect(() => {
        updateSize();
        window.addEventListener("resize", updateSize);
        return () => window.removeEventListener("resize", updateSize);
    }, []);

    if (!organisation) {
        return null;
    }


    const selectUserForTransfer = (user) => {
        setExpanded(false);
        setSelectedUser(user);
        dispatch(fetchUserResponsibilitiesAction(organisation.id, user.id));
    };

    const updateCard = (userIds, responsibility) => {
        if (!userIds.includes(selectedUser.id)) {
            dispatch(transferRemoveUserResponsibility(responsibility.id));
        }
    };

    const renderUserResponsibility = ({ index, style }) => {
        const responsibility = responsibilities[index];
        let allUsers = users.map(item => ({
            label: item.name + " " + item.surname,
            value: item.id
        }));
        let owners = responsibility.owners.map(item => ({
            label: item.name + " " + item.surname,
            value: item.id
        }));

        return (
            <div style={style} key={responsibility.id}>
                <UserResponsibilityItem owners={owners} selectableUsers={allUsers} responsibility={responsibility} updateCard={(userIds) => { updateCard(userIds, responsibility) }} index={index} />
            </div>
        );
    };

    const renderSelectUserStep = () => {

        return (
            <Accordion expanded={expanded === "panel1"}
                onChange={() => onChange("panel1")}>
                <AccordionSummary expandIcon={<Icon name={ICONS.ANGLE_DOWN} />}>
                    <div style={{ fontSize: "20px" }}>
                        {t("organisation.transfer.user.label")}
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="transfer-user-container">
                        {users.map((user) => (
                            <div key={user.email} className="user-row-transfer" onClick={() => selectUserForTransfer(user)}>
                                <div className="user-details">
                                    {user.name} {user.surname}
                                </div>
                                <div className="user-details">
                                    {user.email}
                                </div>
                            </div>
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    };

    const onChange = (panel) => {
        if (typeof panel === "string" && expanded === panel) {
            setExpanded(null);
        } else {
            setExpanded(panel);
        }
    };

    return (
        <div className="users-page">
            <div className="users-page-header">
                <div className="title">{t("organisation.transfer.responsibilities.title")}</div>
                <span>&nbsp;</span>
            </div>

            <div className="cols">
                <div className="col">
                    {renderSelectUserStep()}
                </div>
            </div>
            {selectedUser !== null &&
                <div className="cols">
                    <TransferContext.Provider value={{ setSize, windowWidth }}>
                        <div className="col">
                            <List
                                className="react-window-hidden-scrollbar"
                                height={windowHeight - 320}
                                itemCount={responsibilities.length}
                                itemSize={getSize}
                                width={"100%"}
                                ref={listRef}>
                                {renderUserResponsibility}
                            </List>
                        </div>
                    </TransferContext.Provider>
                </div>
            }
        </div>
    );
};

export default TransferOverview;