import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import UserItem from "./UserItem";
import { fetchUsersAction } from "redux/actions/manageUser";
import ListHeader from "components/ListHeader";



export default function UserList() {

    const [t] = useTranslation();
    const dispatch = useDispatch();
    const { current: organisation } = useSelector(state => state.organisation);
    const { users } = useSelector(state => state.manageUser);

    const fetchUsers = useCallback(() => {
        if (organisation?.id) {
            dispatch(fetchUsersAction(organisation.id));
        }
    }, [dispatch, organisation]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const HEADERS = [
        { field: "name", label: t("users.email.label") },
        { field: "name", label: t("users.name.label") },
        { field: "name", label: t("users.roles.okComply.label") },
        { field: "name", label: t("users.roles.okAudit.label") },
        { field: "name", label: t("users.roles.okPublish.label") },
    ];

    if (!organisation) {
        return null;
    }

    const renderUsers = () => {

        if (!Array.isArray(users)) return null;

        return users.map((user, index) => (
            <UserItem
                key={index}
                index={index}
                user={user}
                organisationId={organisation.id}
            />));
    };


    const length = Array.isArray(users) ? users.length : 0;

    return (
        <>
            <div className="cols">
                <div className="col">
                    <div className="header">
                        <span>
                            {t("Actief")}
                        </span>
                        <span className="number-items">
                            {length}
                        </span>
                        <div>
                            <button className="button"
                                onClick={() => fetchUsers()}>
                                <i className="fas fa-sync-alt" />
                            </button>
                        </div>
                    </div>
                    <div className="page-divider" />
                </div>
            </div>
            <ListHeader items={HEADERS} />
            {renderUsers()}
        </>
    );

}
