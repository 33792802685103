import fetchUserResponsibilitiesAction from "./fetchUserResponsibilitiesAction";

import {
    TRANSFER_RESPONSIBILITIES_SAVE,
    TRANSFER_RESPONSIBILITIES_REMOVE,
    TRANSFER_LOADING
} from "../../types/transfer";


function tranferSaveUserResponsibilities(responsibilities) {
    return {
        type: TRANSFER_RESPONSIBILITIES_SAVE,
        responsibilities
    };
}

function transferRemoveUserResponsibility(responsibilityId) {
    return {
        type: TRANSFER_RESPONSIBILITIES_REMOVE,
        responsibilityId
    };
}

function transferSetLoading(loading) {
    return {
        type: TRANSFER_LOADING,
        loading
    };
}

export {
    fetchUserResponsibilitiesAction,
    tranferSaveUserResponsibilities,
    transferRemoveUserResponsibility,
    transferSetLoading
};