import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import OrganisationCard from "components/Card";
import { moduleKinds, moduleRequestStatuses, moduleRequestType } from "constants/module";
import Button from "shared/components/buttons/Button";
import { Bullets } from "./Bullets";

import "./style.scss";

export default function ComplyModule({ module, requestModule }) {

    const [t] = useTranslation();

    const active = module === moduleRequestStatuses.ACTIVE;

    const renderRequestModuleButton = () => {
        return (module === null || module === moduleRequestStatuses.INACTIVE) &&
            <Button onClick={() =>
                requestModule(moduleKinds.OkComply,
                    moduleRequestType.REQUEST)}
            label={t("organisation.module.request.label")}
            success/>;
    };

    const renderTerminateModuleButton = () => {
        return (module === moduleRequestStatuses.ACTIVE) &&
            <Button onClick={() =>
                requestModule(moduleKinds.OkComply,
                    moduleRequestType.CANCEL)}
            label={t("organisation.module.cancel.label")}
            success/>;
    };

    return (<OrganisationCard heading={t("organisation.module.okcomply.heading")}
        subheading={t("organisation.module.okcomply.subheading")}
        imageUrl={require("assets/img/okcomply-basis.png")}
        module={module}
        selected={active}
        active={active}>
        <Bullets str="organisation.module.okcomply.bullets.bullet"/>
        {renderTerminateModuleButton()}
        {renderRequestModuleButton()}
    </OrganisationCard>);
}

ComplyModule.propTypes = {
    requestModule: PropTypes.func.isRequired,
    module: PropTypes.string
};