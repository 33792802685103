import React, { Component } from "react";
import PropTypes from "prop-types";
import DropdownTreeSelect from "react-dropdown-tree-select";
import Label from "../Label";
import isEqual from "lodash/isEqual";
import { omitDeep } from "helpers";

export const Mode = {
    MULTISELECT: "multiSelect",
    SIMPLESELECT: "simpleSelect",
    RADIOSELECT: "radioSelect",
    HIERARCHICAL: "hierarchical"
};

class TreeSelect extends Component {

    shouldComponentUpdate(nextProps) {
        // fix: otherwise it closes the dropdown after each selection
        const p1 = omitDeep(this.props, ["checked"]);
        const p2 = omitDeep(nextProps, ["checked"]);
        return !isEqual(p1, p2);
    }

    render() {

        const {
            label,
            keepTreeOnSearch,
            placeholder,
            noMatches,
            data,
            onChange,
            className = "select has-shadow"
        } = this.props;

        return (
            <div>
                <Label
                    className="label"
                    label={label}
                />
                <DropdownTreeSelect
                    texts={{ placeholder, noMatches }}
                    className={className}
                    keepTreeOnSearch={keepTreeOnSearch}
                    data={data}
                    onChange={onChange}
                    mode={Mode.HIERARCHICAL}
                    radioSelect={false}
                />
            </div>
        );
    }
}

TreeSelect.propTypes = {
    label: PropTypes.string,
    keepTreeOnSearch: PropTypes.bool,
    placeholder: PropTypes.string,
    noMatches: PropTypes.string,
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default TreeSelect;