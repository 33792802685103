import client from "graphql/client";
import { loader } from "graphql.macro";
import mapNodesToPermissions from "containers/users/helpers/NodesToPermissions";
const inviteUserToOrganisation = loader("graphql/mutations/user/inviteUserToOrganisation.graphql");

export default function inviteUserToOrganisationAction(organisationId) {

    return (dispatch, getState) => {

        const { manageUser } = getState();

        const { user } = manageUser;

        const {
            email,
            okComplyRole,
            okAuditRole,
            okPublishRole,
            organisationRole,
            readPermissions,
            writePermissions,
            okauditChecked,
            okpublishChecked
        } = user;

        const nodes = mapNodesToPermissions(writePermissions, readPermissions);

        return client.mutate({
            mutation: inviteUserToOrganisation,
            variables: {
                organisationId,
                email,
                nodes,
                okComplyRole,
                okAuditRole: okauditChecked ? okAuditRole : undefined,
                okPublishRole: okpublishChecked ? okPublishRole : undefined,
                organisationRole
            }
        });
    };
}
