import isValidIdentifier from "helpers/isValidIdentifier";
import fetchInvitesQuery from "graphql/queries/invites/fetchInvites.graphql";

const disapproveInviteFunction = (organisationId) => {
    return (store, {data}) => {
        try {
            const disapproveInvite = typeof data["disapproveInvite"] !== "undefined" ?
                data["disapproveInvite"] : null;

            if (disapproveInvite) {
                const { id } = disapproveInvite;
                if (isValidIdentifier(id)) {
                    const data = store.readQuery({
                        query: fetchInvitesQuery,
                        variables: {organisationId}
                    });

                    const { invites } = data;
                    const updatedInvites = invites.map((invite) => {
                        if (invite.id === id) {
                            return disapproveInvite;
                        }
                        return invite;
                    });

                    store.writeQuery({
                        query: fetchInvitesQuery,
                        data: {invites: updatedInvites}
                    });
                }
            }
        } catch (error) {
        }
    };
};

export default disapproveInviteFunction;
