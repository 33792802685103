import React from "react";
import { styled } from "@material-ui/styles";
import Icon, { ICONS } from "../../shared/components/Icon";
import { StepConnector, withStyles } from '@material-ui/core';
import "./style.scss";

const ColorlibStepIconRoot = styled('div')(() => ({
    backgroundColor: '#006280',
    zIndex: 1,
    color: 'white',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center'
}));

export const StepContentClasses = withStyles({
    MuiStepContent: {
        root: {
            marginLeft: "24px",
            marginTop: 0,
            borderLeft: "2px solid #006280"
        }
    }
});

export const QontoConnector = withStyles({
    root: {
        paddingBottom: 0
    },
    active: {
        '& $line': {
            border: 0,
            backgroundColor: '#006280',
            borderColor: '#006280',
            borderStyle: 'solid',
            width: 2,
            marginLeft: "12px"
        },
    },
    line: {
        height: 30,
        backgroundColor: '#006280',
        borderColor: '#006280',
        borderStyle: 'solid',
    }
})(StepConnector);

export function ColorlibStepIcon(props) {

    const icons = {
        1: <Icon name={ICONS.USER}/>,
        2: <Icon name={ICONS.ENVELOPE}/>,
        3: <Icon name={ICONS.WARNING}/>,
        4: <Icon name={ICONS.CHECK}/>
    };

    return (
        <ColorlibStepIconRoot>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}