import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Accordion from "components/accordion";
import { moduleRequestStatuses } from "constants/module";
import { Link } from "react-router-dom";

export default function RequestModule({ module, status }) {

    const [t] = useTranslation();

    if (typeof module !== "string") {
        return null;
    }

    const title = `organisation.module.request.${module}.title`.toLowerCase();
    const subtext = `organisation.module.${module}.subtext`.toLowerCase();

    return (
        <Accordion title={title}
                   index={5}
                   subtext={subtext}
                   checked={false}
                   dark={true}
                   open={true}
                   className="dark">
            {!status || status === moduleRequestStatuses.INACTIVE ?
            <Link to="/modules">{t("organisation.module.link")}</Link> :
            <span>{t("organisation.module.status")} {status}</span>}
        </Accordion>);
}

RequestModule.propTypes = {
    text: PropTypes.string
}
