// organisation => nodes
const createNodeTree = (nodes) => {

    let hashTable = Object.create(null);
    nodes.forEach(node => {
        hashTable[node.id] = { ...node, checked: node?.checked, children: []}
    });

    const dataTree = [];

    nodes.forEach( ({id, parentId}) => {
        parentId && typeof hashTable[parentId] !== "undefined" ?
            hashTable[parentId].children.push(hashTable[id]) :
            dataTree.push(hashTable[id]);
    });

    return dataTree[0];
};

export default createNodeTree;