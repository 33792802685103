import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Link, useLocation } from "react-router-dom";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import Icon from "shared/components/Icon";
import { menuItems } from "constants/menu";
import organisationSetByIndex from "redux/actions/organisation/organisationSetByIndex";
import organisationLoad from "redux/actions/organisation/organisationLoad";
import { setLoading, setMembership } from "redux/actions/organisation/index";
import { fetchMembership } from "redux/actions/index";
import { isOwnerOfOrganisation } from "helpers/index";
import OrganisationSelector from "shared/components/OrganisationSelector";
import Modules from "shared/components/Modules";

import "./style.scss";

export default function Navigation() {

    const [t] = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();

    const { organisations, membership, current } = useSelector(state => state.organisation);

    const isUsersPage = () => {
        return location.pathname === "/users" ||
            location.pathname === "/modules" ||
            location.pathname === "/ownership" ||
            location.pathname === "/transfer";
    };

    const onAction = async (index) => {
        if(organisations?.length > 0 && index >= 0) {
            dispatch(setLoading(true));
            const organisation = organisations[index];            
            await dispatch(organisationLoad(organisation.id));
            await dispatch(organisationSetByIndex(index));
            const membershipResult  = await dispatch(fetchMembership(organisation.id));
            await dispatch(setMembership(membershipResult));
            dispatch(setLoading(false));
        }
    };

    const renderMenuItems = () => {

        return menuItems.map((menuItem, index) => {

            const { label, path, icon, className } = menuItem;

            if(!isOwnerOfOrganisation(membership) && path === "/ownership") {
                return null;
            }
            const classes = classnames("menu-item", className && className);
            return (
                <NavLink
                    key={`navigation-${index}`} className={classes} to={path}
                    activeClassName="is-active">
                    <Icon name={icon} spacedRight/>
                    <span className="has-text-white">{t(label)}</span>
                </NavLink>
            );
        });
    };

    if (!isUsersPage()) return null;

    return (
        <nav className="main-navigation" role="navigation">
            <div className="navigation-left">
                <Link className="navigation-logo" to="/">
                    <img
                        alt="OKcomply organisation"
                        src={require("assets/img/logo.png")}
                    />
                    <span>{t("organisation.singular")}</span>
                </Link>
                <div className="menu-items">
                    {renderMenuItems()}
                </div>
            </div>
            <div className="navigation-right">
                <div className="navigation-big organisation">
                    {organisations?.length > 0 &&
                            <OrganisationSelector
                                organisations={organisations}
                                onOrganisationChanged={(index) => onAction(index)}
                            />
                    }
                </div>
                <Modules organisation={current} />
            </div>
        </nav>
    );
}

Navigation.propTypes = {
    userRoleLevel: PropTypes.object
};

