import client from "graphql/client";
import { loader } from "graphql.macro";
const setOwnershipMutation = loader("graphql/mutations/user/setOwnership.graphql");

export default function setOwnershipAction(invite) {
    return async () => {
            const { id, userId, organisationId } = invite;
            return client.mutate({
                mutation: setOwnershipMutation,
                variables: {
                    ownershipInviteId: id,
                    userId,
                    organisationId
                }
            })
    };
}