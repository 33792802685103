import React from "react";
import PropTypes from "prop-types";

const Label = ({
    label,
    id,
    className,
    children
}) => (
    <label
        id={id && `${id}Label`}
        htmlFor={id}
        className="label">
        {label}
        {children}
    </label>
);

Label.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string,PropTypes.object]),
    id: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.any
};

Label.displayName = "Label";

export default Label;