import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { manageUserResetUser } from "redux/actions/manageUser";
import { Loading } from "components/Loading";
import { useManageUser } from "../form/useManageUser";
import InputText from "shared/components/inputs/InputText";
import Button from "shared/components/buttons/Button";
import OkSnackbar from "shared/components/OkSnackbar";
import Organisation from "containers/users/form/Organisation";
import { useForm } from "react-hook-form";
import { organisationRolesArray } from "constants/role";
import { useHistory } from "react-router-dom";

export default function EditUser1({ nextStep }) {

    const [t] = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const [invalidRole, setInvalidRole] = useState(false);

    const { register, handleSubmit, errors } = useForm();

    const {
        email,
        onChangeOrganisationRole,
        onChangeEmail,
        organisationRole,
        loading
    } = useManageUser();

    const onCancel = () => {
        dispatch(manageUserResetUser());
        history.push("/users");
    };

    const onSubmit = () => {
        setInvalidRole(false);
        if (!organisationRolesArray.some(role => role === organisationRole)) {
            setInvalidRole(true);
        } else {
            nextStep();
        }
    };

    if (loading) return <Loading/>;

    return (
        <div className="new-user">
            <div className="cols">
                <div className="col">
                    <InputText
                        label={t("user.email.label")}
                        inputRef={register({ required: true })}
                        name="email"
                        defaultValue={email}
                        error={errors?.email}
                        onChange={onChangeEmail}
                    />
                </div>
            </div>
            <div className="cols">
                <div className="col">
                    <Organisation
                        setRole={onChangeOrganisationRole}
                        role={organisationRole}
                        error={invalidRole}/>
                </div>
            </div>
            <div className="cols">
                <div className="col">
                    <Button label={t("button.next.label")}
                            onClick={handleSubmit(onSubmit)}
                            secondary
                            spacedRight
                    />
                    <Button label={t("button.cancel.label")}
                            onClick={onCancel}
                            outlined
                            spacedRight
                    />
                </div>
            </div>
            <OkSnackbar/>
        </div>);

}

EditUser1.propTypes = {
    nextStep: PropTypes.func.isRequired
};
