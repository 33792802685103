import produce from "immer";

import {
    MANAGE_USER_CURRENT_STATE_SET,
    MANAGE_USER_SAVE,
    MANAGE_USER_RESET,
    MANAGE_USER_LOADING,
    MANAGE_USER_USERS_SAVE,
    MANAGE_USER_INVITES_SAVE,
    MANAGE_USER_READ_PERMISSIONS_SAVE,
    MANAGE_USER_WRITE_PERMISSIONS_SAVE,
    MANAGE_USER_EMAIL_SAVE,
    MANAGE_USER_FIRSTNAME_SAVE,
    MANAGE_USER_LASTNAME_SAVE,
    MANAGE_USER_ORGANISATION_ROLE_SAVE,
    MANAGE_USER_OKCOMPLY_ROLE_SAVE,
    MANAGE_USER_OKAUDIT_ROLE_SAVE,
    MANAGE_USER_OKPUBLISH_ROLE_SAVE,
    USERS_LOADED,
    MANAGE_USER_SET_MODULE_CHECKED
} from "../types/manageUser";

import {
    okComplyRoles,
    organisationRoles,
    okPublishRoles,
    okAuditRoles
} from "../../constants/role";
import { ICONS } from "shared/components/Icon";

const initialState = {
    user: {
        id: "",
        email: "",
        firstname: "",
        lastname: "",
        // nodePermissionInputType { grant, nodeId, depth }
        nodes: [],
        okComplyRole: okComplyRoles.EXECUTOR,
        okPublishRole: okPublishRoles.AUTHOR,
        okAuditRole: okAuditRoles.AUDITOR,
        organisationRole: organisationRoles.USER,
        writePermissions: [],
        readPermissions: [],
        okcomplyChecked: true, // mandatory cannot be changed
        okauditChecked: false,
        okpublishChecked: false,
        organisationChecked: true // mandatory
    },
    currentState: 1,
    users: [],
    responsibilities: [],
    invites: [],
    loading: false
};

export const MODULES = {
    OKCOMPLY: "OKCOMPLY",
    OKAUDIT: "OKAUDIT",
    OKPUBLISH: "OKPUBLISH",
    ORGANISATION: "ORGANISATION"
};

const manageUserReducer = produce((draft, action) => {

    switch (action.type) {
        case MANAGE_USER_CURRENT_STATE_SET: {
            draft.currentState = action.currentState;
            break;
        }
        case MANAGE_USER_SAVE: {
            draft.user = action.user;
            break;
        }
        case MANAGE_USER_RESET: {
            draft.user = initialState.user;
            draft.currentState = initialState.currentState;
            draft.users = initialState.users;
            draft.loading = initialState.loading;
            break;
        }
        case MANAGE_USER_USERS_SAVE: {
            draft.users = action.users;
            break;
        }
        case USERS_LOADED: {
            draft.users = action.users;
            break;
        }
        case MANAGE_USER_INVITES_SAVE: {
            draft.invites = action.invites;
            break;
        }
        case MANAGE_USER_LOADING: {
            draft.loading = action.loading;
            break;
        }
        case MANAGE_USER_READ_PERMISSIONS_SAVE: {
            draft.user.readPermissions =  JSON.parse(JSON.stringify(action.permissions));
            break;
        }
        case MANAGE_USER_WRITE_PERMISSIONS_SAVE: {
            draft.user.writePermissions = JSON.parse(JSON.stringify(action.permissions));
            break;
        }
        case MANAGE_USER_EMAIL_SAVE: {
            draft.user.email = action.email;
            break;
        }
        case MANAGE_USER_FIRSTNAME_SAVE: {
            draft.user.firstname = action.firstname;
            break;
        }
        case MANAGE_USER_LASTNAME_SAVE: {
            draft.user.lastname = action.lastname;
            break;
        }
        case MANAGE_USER_ORGANISATION_ROLE_SAVE: {
            draft.user.organisationRole = action.role;
            break;
        }
        case MANAGE_USER_OKCOMPLY_ROLE_SAVE: {
            draft.user.okComplyRole = action.role;
            break;
        }
        case MANAGE_USER_OKAUDIT_ROLE_SAVE: {
            draft.user.okAuditRole = action.role;
            break;
        }
        case MANAGE_USER_OKPUBLISH_ROLE_SAVE: {
            draft.user.okPublishRole = action.role;
            break;
        }
        case MANAGE_USER_SET_MODULE_CHECKED: {
            const { module, checked } = action;
            switch (module) {
                case MODULES.OKCOMPLY:
                    draft.user.okcomplyChecked = checked;
                    break;
                case MODULES.ORGANISATION:
                    draft.user.organisationChecked = checked;
                    break;
                case MODULES.OKPUBLISH:
                    draft.user.okpublishChecked = checked;
                    break;
                case MODULES.OKAUDIT:
                    draft.user.okauditChecked = checked;
                    break;
                default:
                    break;
            }
            break;
        }
        
        default:
            return;
    }
}, initialState);


export default manageUserReducer;
