import { HttpLink } from "apollo-link-http";
import Cookies from "universal-cookie";
import { ApolloClient } from "apollo-client";
import { InMemoryCache, IntrospectionFragmentMatcher  } from "apollo-cache-inmemory";

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
        // eslint-disable-next-line id-match
        __schema: {
            types: [
                {
                    kind: "UNION",
                    name: "MeasureAttributeType",
                    possibleTypes: [
                        {
                            name: "PeriodType",
                        },
                        {
                            name: "PhaseShiftType"
                        }]
                },
                {
                    kind: "UNION",
                    name: "TaskableType",
                    possibleTypes: [
                        {
                            name: "DossierType",
                        },
                        {
                            name: "ReportType"
                        }]
                },
                {
                    kind: "UNION",
                    name: "AttributeType",
                    possibleTypes: [
                        {
                            name: "AttributeAssetType"
                        },
                        {
                            name: "AttributeGroupType"
                        },
                        {
                            name: "AttributePersonType"
                        },
                        {
                            name: "AttributeStructureType"
                        },
                        {
                            name: "FileType"
                        }
                    ]
                }
            ]
        }
    }
});


const cookies = new Cookies();
const token = cookies.get("id_token");

const isDev = (!process.env.NODE_ENV || process.env.NODE_ENV === "development");

const environment = isDev ? "development" :
    window.location.href.includes("acceptance") ?
        "acceptance" :
        "production";

const ENVIRONMENTS = {
    "development": "http://localhost:1337/graphql",
    "acceptance": "https://acceptance.okcomply.com/graphql",
    "production": "https://my.okcomply.com/graphql"
};
const client = new ApolloClient({
    link: new HttpLink({
        // uri: `${window.location.host}/graphql`,
        // TODO: FIXME: make alias for publish.okcomply.com/graphql in order
        // to connect with OKAPI without 3 different env files
        uri: ENVIRONMENTS[environment],
        headers: {
            authorization: `Bearer ${token}`,
        },
    }),
    cache: new InMemoryCache({ fragmentMatcher }),
});


export default client;