import fetchUserWithMemberShipAction from "./fetchUserWithMemberShipAction";
import inviteUserToOrganisationAction from "./inviteUserToOrganisationAction";
import removeUserAction from "./removeUserAction";
import removeUserFunction from "./removeUserFunction";
import editMembershipAction from "./editMembershipAction";
import fetchInvitesAction from "./fetchInvitesAction";
import fetchUsersAction from "./fetchUsersAction";
import fetchUserResponsibilitiesAction from "../transfer/fetchUserResponsibilitiesAction";

import {
    MANAGE_USER_CURRENT_STATE_SET,
    MANAGE_USER_INVITES_SAVE,
    MANAGE_USER_LOADING,
    MANAGE_USER_READ_PERMISSIONS_SAVE,
    MANAGE_USER_RESET,
    MANAGE_USER_SAVE,
    MANAGE_USER_USERS_SAVE,
    MANAGE_USER_WRITE_PERMISSIONS_SAVE,
    MANAGE_USER_EMAIL_SAVE,
    MANAGE_USER_FIRSTNAME_SAVE,
    MANAGE_USER_LASTNAME_SAVE,
    MANAGE_USER_OKCOMPLY_ROLE_SAVE,
    MANAGE_USER_OKAUDIT_ROLE_SAVE,
    MANAGE_USER_OKPUBLISH_ROLE_SAVE,
    MANAGE_USER_ORGANISATION_ROLE_SAVE,
    MANAGE_USER_SET_MODULE_CHECKED,
} from "../../types/manageUser";

function manageUserSetCurrentState(currentState) {
    return {
        type: MANAGE_USER_CURRENT_STATE_SET,
        currentState
    };
}

function manageUserSaveUser(user) {
    return {
        type: MANAGE_USER_SAVE,
        user
    };
}

function manageUserResetUser() {
    return {
        type: MANAGE_USER_RESET
    };
}

function manageUserSaveUsers(users) {
    return {
        type: MANAGE_USER_USERS_SAVE,
        users
    };
}

function manageUserSaveInvites(invites) {
    return {
        type: MANAGE_USER_INVITES_SAVE,
        invites
    };
}

function manageUserSetLoading(loading) {
    return {
        type: MANAGE_USER_LOADING,
        loading
    };
}

function manageUserSaveWritePermissions(permissions) {
    return {
        type: MANAGE_USER_WRITE_PERMISSIONS_SAVE,
        permissions
    };
}

function manageUserSaveReadPermissions(permissions) {
    return {
        type: MANAGE_USER_READ_PERMISSIONS_SAVE,
        permissions
    };
}

function manageUserSaveEmail(email) {
    return {
        type: MANAGE_USER_EMAIL_SAVE,
        email
    };
}

function manageUserSaveFirstname(firstname) {
    return {
        type: MANAGE_USER_FIRSTNAME_SAVE,
        firstname
    };
}

function manageUserSaveLastname(lastname) {
    return {
        type: MANAGE_USER_LASTNAME_SAVE,
        lastname
    };
}

function manageUserSaveOrganisationRole(role) {
    return {
        type: MANAGE_USER_ORGANISATION_ROLE_SAVE,
        role
    };
}

function manageUserSaveOkComplyRole(role) {
    return {
        type: MANAGE_USER_OKCOMPLY_ROLE_SAVE,
        role
    };
}

function manageUserSaveOkAuditRole(role) {
    return {
        type: MANAGE_USER_OKAUDIT_ROLE_SAVE,
        role
    };
}

function manageUserSaveOkPublishRole(role) {
    return {
        type: MANAGE_USER_OKPUBLISH_ROLE_SAVE,
        role
    };
}

function manageUserSetChecked(module, checked) {
    return {
        type: MANAGE_USER_SET_MODULE_CHECKED,
        module,
        checked
    };
}

export {
    editMembershipAction,
    fetchUserWithMemberShipAction,
    inviteUserToOrganisationAction,
    fetchInvitesAction,
    fetchUsersAction,
    fetchUserResponsibilitiesAction,
    removeUserAction,
    removeUserFunction,
    manageUserSetCurrentState,
    manageUserSaveUser,
    manageUserResetUser,
    manageUserSaveInvites,
    manageUserSaveUsers,
    manageUserSetLoading,
    manageUserSaveWritePermissions,
    manageUserSaveReadPermissions,
    manageUserSaveEmail,
    manageUserSaveFirstname,
    manageUserSaveLastname,
    manageUserSaveOkComplyRole,
    manageUserSaveOrganisationRole,
    manageUserSaveOkAuditRole,
    manageUserSaveOkPublishRole,
    manageUserSetChecked,
};