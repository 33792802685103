import client from "graphql/client";
import { loader } from "graphql.macro";
import revokeInviteFunction from "./revokeInviteFunction";
const revokeInvite = loader("graphql/mutations/invites/revokeInvite.graphql");

export default function revokeInviteAction(organisationId, state) {
    return () => {
        const { inviteId, sequenceNumber } = state;
        const series = {
            id: inviteId,
            sequenceNumber: sequenceNumber + 1
        };

        return client.mutate({
            mutation: revokeInvite,
            variables: {organisationId, series},
            update: revokeInviteFunction(organisationId)
        });
    };
}
