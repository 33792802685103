import client from "graphql/client";
import { loader } from "graphql.macro";
import { snackbarError } from "shared/redux/actions/Snackbar";
import createErrorMessage from "helpers/createErrorMessage";
import { setOrganisation } from "redux/actions/organisation/index";
import { setNodeParents } from "helpers/setNodeParents";

const fetchOrganisation = loader("shared/graphql/queries/organisation/fetchOrganisation.graphql");

export default function organisationLoad(organisationId) {
    return async (dispatch) => {
        client.query({
            query: fetchOrganisation,
            variables: { organisationId }
        }).then(response => {
            const { data } = response;
            const { organisation } = data;
            const { nodes, root } = organisation;
            const newNodes = JSON.parse(JSON.stringify(nodes));
            setNodeParents(root, newNodes);
            const newOrganisation = {...organisation, nodes: newNodes};
            dispatch(setOrganisation(newOrganisation));
        }).catch(e => {
            // eslint-disable-next-line no-console
            console.error(e);
            dispatch(snackbarError(createErrorMessage(e, "organisation.load.failed")));
        });
    };
}
