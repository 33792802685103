import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { manageUserSetChecked } from "redux/actions/manageUser";
import { MODULES } from "redux/reducers/manageUserReducer";
import Accordion from "components/accordion";
import SelectOkComplyRole from "./SelectOkComplyRole";
import TreeSelect from "components/TreeSelect/TreeSelect";
import classNames from "classnames";

export default function OkComply(props) {

    const [t] = useTranslation();

    const dispatch = useDispatch();
    const manageUser = useSelector(state => state.manageUser);
    const { user } = manageUser;
    const { okcomplyChecked } = user;

    const {
        tree,
        showReadRights = true,
        setRole,
        role,
        onChangeReadPermissions,
        onChangeWritePermissions,
        writePermissionTree,
        readPermissionTree,
        writePermissionError
    } = props;

    const onChecked = (event, index, checked) => {
        dispatch(manageUserSetChecked(MODULES.OKCOMPLY, !checked));
    };

    const className = classNames("select select-has-border",
        writePermissionError && "error");

    return (
        <Accordion onChecked={onChecked}
                   index={1}
                   checked={okcomplyChecked}
                   subtext={"organisation.module.okcomply.subtext"}
                   title={"organisation.module.okcomply.title"}>
            <SelectOkComplyRole setRole={setRole} role={role}/>
            <div className="rights">
                <div className="cols">
                    <div className="col">
                        <TreeSelect
                            label={t("user.permissions.write.label")}
                            className={className}
                            keepTreeOnSearch
                            placeholder={t("user.permissions.common.placeholder")}
                            noMatches={t("user.permissions.common.nomatch")}
                            data={tree || writePermissionTree}
                            onChange={onChangeWritePermissions}
                        />
                    </div>
                </div>
                {showReadRights &&
                <div className="cols">
                    <div className="col">
                        <TreeSelect
                            label={t("user.permissions.read.label")}
                            className={"select select-has-border"}
                            keepTreeOnSearch
                            placeholder={t("user.permissions.common.placeholder")}
                            noMatches={t("user.permissions.common.nomatch")}
                            data={tree || readPermissionTree}
                            onChange={onChangeReadPermissions}
                        />
                    </div>
                </div>}
            </div>
        </Accordion>);
}

OkComply.propTypes = {
    setRole: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired,
    tree: PropTypes.object,
    onChangeWritePermissions: PropTypes.func.isRequired,
    onChangeReadPermissions: PropTypes.func.isRequired,
    writePermissionTree: PropTypes.object,
    readPermissionTree: PropTypes.object,
    okComplyRoleError: PropTypes.bool,
    showReadRights: PropTypes.bool,
    writePermissionsError: PropTypes.bool
};
