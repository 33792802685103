export default function  mapNodesToPermissions(writePermissions, readPermissions) {
    const mapPermissions = (nodes, permissiontype) => {
        return nodes.map(node => {
            return {
                grant: permissiontype,
                nodeId: node.id,
                depth: null
            };
        });
    };
    return mapPermissions(writePermissions, "WRITE").concat(mapPermissions(readPermissions, "READ"));
};