import React from "react";
import { withStyles } from "@material-ui/core";
import Radio from "@material-ui/core/Radio/Radio";

const GreenRadio = withStyles({
    root: {
        color: "#33de8e",
        "&$checked": {
            color: "#33de8e"
        }
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

export default GreenRadio;