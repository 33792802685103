import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getRootHistory } from "routes/RouterHelpers";
import { createNodeTree } from "helpers";
import Button from "shared/components/buttons/Button";
import { snackbarError, snackbarSuccess } from "shared/redux/actions/Snackbar";
import OkSnackbar from "shared/components/OkSnackbar";
import { useManageUser } from "../form/useManageUser";
import RequestModule from "../form/RequestModule";
import OkAudit from "../form/OkAudit";
import OkPublish from "../form/OkPublish";
import OkComply from "../form/OkComply";
import {
    manageUserResetUser,
    inviteUserToOrganisationAction,
    fetchInvitesAction
} from "redux/actions/manageUser";
import {
    okAuditRolesArray,
    okComplyRoles,
    okComplyRolesArray,
    okPublishRoleArray
} from "constants/role";
import { moduleKinds, moduleRequestStatuses } from "constants/module";

export default function NewUser2({ previousStep }) {

    const [t] = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const {
        writePermissions,
        onChangeReadPermissions,
        onChangeWritePermissions,
        onChangeOkComplyRole,
        onChangeOkAuditRole,
        onChangeOkPublishRole,
        okComplyRole,
        okAuditRole,
        okPublishRole,
        okpublishChecked,
        okauditChecked
    } = useManageUser();

    const { current: organisation } = useSelector(state => state.organisation);
    const { id: organisationId, nodes, modules } = organisation;

    const initialErrorState = {
        okComplyRole: false,
        okAuditRole: false,
        okPublishRole: false,
        writePermissions: false
    };

    const [errors, setErrors] = useState(initialErrorState)
    const [tree, setTree] = useState({});

    const initNodeTree = useCallback(() => {
        return createNodeTree([...nodes]);
    }, [nodes]);

    useEffect(() => {

        const tree = initNodeTree();
        setTree(tree || {});

    }, [organisationId, initNodeTree]);


    const handleSubmit = () => {

        const isValidRole = (selectedRole, roles) => {
            return roles.some(role => role === selectedRole);
        }

        const isValidPermissions = (permissions) => {
            return Array.isArray(permissions) && permissions.length > 0;
        };

        const errorState = Object.assign({}, initialErrorState);

        if (!isValidRole(okComplyRole, okComplyRolesArray)) {
            errorState.okComplyRole = true;
        }

        if (okauditChecked && !isValidRole(okAuditRole, okAuditRolesArray)) {
            errorState.okAuditRole = true;
        }

        if (okpublishChecked && !isValidRole(okPublishRole, okPublishRoleArray)) {
            errorState.okPublishRole = true;
        }
        if (!isValidPermissions(writePermissions)) {
            errorState.writePermissions = true;
        }

        if (errorState.okComplyRole ||
            errorState.okAuditRole ||
            errorState.okPublishRole ||
            errorState.writePermissions) {
            setErrors(errorState);
            return;
        }

        dispatch(inviteUserToOrganisationAction(organisationId))
            .then(({ data }) => {
                const { inviteToOrganisation } = data;
                const { sendTo } = inviteToOrganisation;
                const message = `${t("user.singular")} ${sendTo} ${t("user.invite.success")}`;
                dispatch(snackbarSuccess(message));
                dispatch(fetchInvitesAction(organisationId));
                dispatch(manageUserResetUser());
                history.push("/users");
            })
            .catch(error => {
                if (error?.graphQLErrors !== undefined &&
                    error?.graphQLErrors[0] !== undefined &&
                    typeof error.graphQLErrors[0].message === "string") {
                    const errorMessage = t(error.graphQLErrors[0].message);
                    dispatch(snackbarError(errorMessage));
                } else {
                    dispatch(snackbarError(t("unknown.error.occurred")));
                }
            });
    };

    const onCancel = () => {
        dispatch(manageUserResetUser());
        getRootHistory().push("/users");
    };

    const getStatus = (module) => {
        let status = moduleRequestStatuses.INACTIVE;
        if (module === moduleRequestStatuses.ACTIVE) {
            status = moduleRequestStatuses.ACTIVE;
        } else if (module === moduleRequestStatuses.PENDING) {
            status = moduleRequestStatuses.PENDING;
        }
        return status;
    };

    const renderPublishModule = () => {

        const status = getStatus(modules?.okPublish);

        return status === moduleRequestStatuses.ACTIVE ?
            (<div className="cols">
                <div className="col">
                    <OkPublish
                        setRole={onChangeOkPublishRole}
                        role={okPublishRole}
                        error={errors.okPublishRole} />
                </div>
            </div>) : <RequestModule module={moduleKinds.OkPublish}
                status={status} />;
    };

    const renderAuditModule = () => {

        const status = getStatus(modules?.okAudit);

        return status === moduleRequestStatuses.ACTIVE ?
            (<div className="cols">
                <div className="col">
                    <OkAudit
                        setRole={onChangeOkAuditRole}
                        role={okAuditRole}
                        error={errors.okAuditRole} />
                </div>
            </div>
            ) : <RequestModule module={moduleKinds.OkAudit}
                status={status} />;
    };

    return (
        <div className="new-user">
            <div className="cols">
                <div className="col">
                    <OkComply
                        setRole={onChangeOkComplyRole}
                        role={okComplyRole}
                        tree={tree}
                        onChangeReadPermissions={onChangeReadPermissions}
                        onChangeWritePermissions={onChangeWritePermissions}
                        showReadRights={okComplyRole !== okComplyRoles.EXECUTOR}
                        okComplyRoleError={errors.okComplyRole}
                        writePermissionError={errors.writePermissions} />
                </div>
            </div>
            {renderAuditModule()}
            {renderPublishModule()}
            <div className="cols">
                <div className="col">
                    <Button
                        label={t("button.save.label")}
                        onClick={handleSubmit}
                        success
                        spacedRight
                        dataTestId={"userSave"}
                    />
                    <Button
                        label={t("button.previous.label")}
                        onClick={() => previousStep()}
                        spacedLeft
                        secondary
                        dataTestId={"userSave"}
                    />
                    <Button
                        label={t("button.cancel.label")}
                        onClick={onCancel}
                        outlined
                        spacedRight
                        dataTestId={"userCancel"}
                    />
                </div>
            </div>
            <OkSnackbar />
        </div>
    );
}
