import React, { useEffect } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import isAuthenticated from "./shared/authentication/isAuthenticated";
import Navigation from "./components/Navigation";
import organisationLoadAll from "./redux/actions/organisation/organisationLoadAll";
import userProfileLoad from "./redux/actions/organisation/userProfileLoad";
import NewUserWithSteps from "./containers/users/add/NewUserwithSteps";
import EditUserWithSteps from "./containers/users/edit/EditUserWithSteps";
import Modules from "./containers/modules/index";
import Transfer from "./containers/transfer/index";
import Users from "./containers/users/index";
import Ownership from "./containers/ownership/index";
import TransferOwnership from "containers/ownership/TransferOwnership";
import Authentication from "shared/authentication/Authentication";
import OkSnackbar from "shared/components/OkSnackbar";

const AppRouter = ({ history }) => {

    const dispatch = useDispatch();

    useEffect(() => {
        const path = history.location.pathname;
        if (!isAuthenticated() && path !== "/login") {
            history.push("/login");
        }
    }, [history]);

    const loadAll = async () => {
        await dispatch(organisationLoadAll());
        await dispatch(userProfileLoad());
    };

    useEffect(() => {
        loadAll();
    }, []);

    const unAuthenticatedRoutes = () => {
        return (
            <>
                <Route
                    path="/login"
                    render={() =>
                        <Authentication defaultRoute="/users" />
                    }
                />
            </>
        );
    };

    const authenticatedRoutes = () => {

        return (
            <div className="App">
                <Navigation />
                <Route
                    exact
                    default
                    path="/users"
                    component={Users} />
                <Route
                    exact
                    default
                    path="/users/:id/edit"
                    component={EditUserWithSteps} />
                <Route
                    exact
                    default
                    path="/users/new"
                    component={NewUserWithSteps} />
                <Route
                    exact
                    default
                    path="/modules"
                    component={Modules} />
                <Route
                    exact
                    path="/ownership"
                    component={Ownership} />
                <Route
                    exact
                    default
                    path="/transfer"
                    component={Transfer} />
                <Route
                    path="/ownership/verify/:inviteCode"
                    component={TransferOwnership} />
                <Route exact path="/">
                    <Redirect to="/users" />
                </Route>
                <OkSnackbar fixed />
            </div>
        );
    };

    return (
        <Switch>
            <>
                {isAuthenticated() ?
                    authenticatedRoutes() :
                    unAuthenticatedRoutes()
                }
            </>
        </Switch>
    );
};

AppRouter.propTypes = {
    history: PropTypes.object.isRequired,
};


export default withRouter(AppRouter);