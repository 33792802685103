import client from "graphql/client";
import { tranferSaveUserResponsibilities, transferSetLoading } from "../transfer/index";
import { loader } from "graphql.macro";
const fetchUserResponsibilitiesQuery = loader("graphql/queries/users/fetchUserResponsibilities.graphql");
 
export default function fetchUserResponsibilitiesAction(organisationId, userId) {

    return (dispatch) => {
        dispatch(tranferSaveUserResponsibilities(null));
        dispatch(transferSetLoading(true));
        return client.query({
            query: fetchUserResponsibilitiesQuery,
            variables: { organisationId, userId },
            fetchPolicy: "network-only"
        }).then(result => {
            const { data } = result;
            const { userResponsibilities } = data;
            dispatch(tranferSaveUserResponsibilities(userResponsibilities));
            dispatch(transferSetLoading(false));
        }).catch(() => {
            dispatch(transferSetLoading(false));
        });
    };
}