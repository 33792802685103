export const MANAGE_USER_CURRENT_STATE_SET = "MANAGE_USER_CURRENT_STATE_SET";
export const MANAGE_USER_SAVE = "MANAGE_USER_SAVE";
export const MANAGE_USER_RESET = "MANAGE_USER_RESET";
export const MANAGE_USER_USERS_SAVE = "MANAGE_USER_USERS_SAVE";
export const MANAGE_USER_INVITES_SAVE = "MANAGE_USER_INVITES_SAVE";
export const MANAGE_USER_LOADING = "MANAGE_USER_LOADING";
export const MANAGE_USER_READ_PERMISSIONS_SAVE = "MANAGE_USER_READ_PERMISSIONS_SAVE";
export const MANAGE_USER_WRITE_PERMISSIONS_SAVE = "MANAGE_USER_WRITE_PERMISSIONS_SAVE";
export const MANAGE_USER_EMAIL_SAVE = "MANAGE_USER_EMAIL_SAVE";
export const MANAGE_USER_FIRSTNAME_SAVE = "MANAGE_USER_FIRSTNAME_SAVE";
export const MANAGE_USER_LASTNAME_SAVE = "MANAGE_USER_LASTNAME_SAVE";
export const MANAGE_USER_OKCOMPLY_ROLE_SAVE = "MANAGE_USER_OKCOMPLY_ROLE_SAVE";
export const MANAGE_USER_OKAUDIT_ROLE_SAVE = "MANAGE_USER_OKAUDIT_ROLE_SAVE";
export const MANAGE_USER_OKPUBLISH_ROLE_SAVE = "MANAGE_USER_OKPUBLISH_ROLE_SAVE";
export const MANAGE_USER_ORGANISATION_ROLE_SAVE = "MANAGE_USER_ORGANISATION_ROLE_SAVE";
export const MANAGE_USER_SET_MODULE_CHECKED = "MANAGE_USER_SET_MODULE_CHECKED";
export const USERS_LOADED = "USERS_LOADED";
