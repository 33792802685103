import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvitesAction } from "redux/actions/manageUser";
import InviteItem from "../add/InviteItem";
import { inviteStatusses } from "constants/invite";

export default function InvitedList() {

    const [t] = useTranslation();
    const dispatch = useDispatch();
    const { invites } = useSelector(state => state.manageUser);
    const { current: organisation } = useSelector(state => state.organisation);

    useEffect(() => {
        if (organisation?.id) {
            dispatch(fetchInvitesAction(organisation.id));
        }
    }, [organisation, dispatch]);

    const inviteFilter = (invite) => {
        return [inviteStatusses.NEW,
                inviteStatusses.SENT,
                inviteStatusses.PENDING,
                inviteStatusses.REVOKED,
                inviteStatusses.DISAPPROVED]
            .some((status) => status === invite.status);
    };

    const filteredInvites = Array.isArray(invites) ? invites.filter(inviteFilter) : [];

    const refreshInvites = () => {
        if (organisation?.id) {
            dispatch(fetchInvitesAction(organisation.id));
        }
    };

    const length = Array.isArray(filteredInvites) ? filteredInvites.length : 0;

    return (
        <>
            <div className="spacer-double"/>
            <div className="cols">
                <div className="col">
                    <div className="header">
                    <span className="has-text-weight-bold has-text-black">
                        {t("Uitgenodigd")}
                    </span>
                        <span className="number-items">{length}</span>
                        <div>
                            <button className="button"
                                    onClick={() => refreshInvites()}>
                                <i className="fas fa-sync-alt"/>
                            </button>
                        </div>
                    </div>
                    <div className="page-divider"/>
                </div>
            </div>
            {filteredInvites.map((invite, index) =>
                <InviteItem
                    key={index}
                    index={index}
                    invite={invite}
                    organisationId={organisation.id}
                />
            )}
        </>
    );
}