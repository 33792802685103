import client from "graphql/client";
import { loader } from "graphql.macro";

const inviteToOwnershipMutation = loader("graphql/mutations/invites/inviteToOwnership.graphql");

export default function inviteToOwnershipAction(email, userId) {
    return async (dispatch, getState) => {

        const organisationId = getState().organisation.current.id;

        const ownershipInvite = {
            userId: userId, // user to transfer ownership to
            organisationId: organisationId // applies to this organisation
        };

        return client.mutate({
            mutation: inviteToOwnershipMutation,
            variables: {
                ownershipInvite,
                email: email,
                organisationId
            }
        });
    };
}