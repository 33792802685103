import React from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import classNames from "classnames";

import Icon from "shared/components/Icon";

import "./style.scss";

const ModuleItem = ({
    image,
    icon,
    path,
    label,
    description,
    disabled,
    organisationId
}) => {

    const history = useHistory();
    
    const navigate = () => {
        // See if we're on localhost
        const isLocalHost = window.location.host.includes("localhost");
        // organisationId is only set if the ModuleItem points to another domain
        const isInOtherDomain = !!organisationId;

        if (isInOtherDomain) {
            // If we're on localhost we shouldn't navigate
            if(!isLocalHost) {
                window.location = `${path}/?organisationId=${organisationId}`;
            }
        } else {
            history.push(path);
        }
    };

    const moduleItemClasses = classNames(
        "module-item",
        disabled && "disabled"
    );

    return (
        <div onClick={navigate} className={moduleItemClasses}>
            {image ?
                <img className="module-image" src={image} /> :
                <div className="module-icon">
                    <Icon name={icon} />
                </div>
            }
            <div className="module-content">
                <span className="module-label">{label}</span>
                <span className="module-description">{description}</span>
            </div>
        </div>
    );
};

ModuleItem.propTypes = {
    image: PropTypes.string,
    icon: PropTypes.string,
    path: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    organisationId: PropTypes.string
};


export default ModuleItem;