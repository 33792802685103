import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import SelectOkPublishRole from "containers/users/form/SelectOkPublishRole";
import { manageUserSetChecked } from "redux/actions/manageUser";
import { MODULES } from "redux/reducers/manageUserReducer";
import Accordion from "components/accordion";

export default function OkPublish({ setRole, role }) {

    const dispatch = useDispatch();
    const manageUser = useSelector(state => state.manageUser);
    const { user } = manageUser;
    const { okpublishChecked } = user;

    const onChecked = (event, index, checked) => {
        dispatch(manageUserSetChecked(MODULES.OKPUBLISH, !checked));
    };

    return (
        <Accordion onChecked={onChecked}
                   index={3}
                   checked={okpublishChecked}
                   subtext={"organisation.module.okpublish.subtext"}
                   title={"organisation.module.okpublish.title"}>
            <SelectOkPublishRole setRole={setRole} role={role}/>
        </Accordion>);

}

OkPublish.propTypes = {
    setRole: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired
};