import client from "../../graphql/client";
import { loader }from "graphql.macro";
const fetchUserMembershipQuery = loader("../../graphql/queries/users/fetchUserMembership.graphql");

const fetchMembership = function(organisationId) {
    return function () {
        return new Promise(function (resolve, reject) {
            client.query({
                query: fetchUserMembershipQuery,
                variables: {organisationId: organisationId}
            }).then(({data}) => {
                const { userMembership } = data;
                resolve(userMembership);
            }).catch((error) => {
                reject(error);
            });
        });
    };
};

export { fetchMembership };