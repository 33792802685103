import client from "graphql/client";
import { manageUserSaveUsers, manageUserSetLoading } from "../manageUser";
import { loader } from "graphql.macro";
const fetchUsersQuery = loader("graphql/queries/users/fetchUsers.graphql");

export default function fetchUsersAction(organisationId) {

    return (dispatch) => {
        dispatch(manageUserSaveUsers([]));
        dispatch(manageUserSetLoading(true));
        return client.query({
            query: fetchUsersQuery,
            variables: { organisationId },
            fetchPolicy: "network-only"
        }).then(result => {
            const { data } = result;
            const { users } = data;
            dispatch(manageUserSaveUsers(users));
            dispatch(manageUserSetLoading(false));
        }).catch(() => {
            dispatch(manageUserSetLoading(false));
        });
    };
}
