import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import GreenRadio from "containers/users/form/RadioButton";
import { useTranslation } from "react-i18next";
import { organisationRoles } from "constants/role";
import { Bullets } from "containers/modules/Bullets";


export default function SelectOrganisationRole({ role, setRole }) {

    const [t] = useTranslation();

    // Changing the role of owner is a different flow

    // USER
    const className1 = classnames("containerRoot",
        role === organisationRoles.USER && "selected");

    // ORGANISATION_MANAGER
    const className2 = classnames("containerRoot",
        role === organisationRoles.ORGANISATION_MANAGER && "selected");

    // ADMINISTRATOR
    const className3 = classnames("containerRoot",
        role === organisationRoles.ADMINISTRATOR && "selected");

    const onChangeRole = useCallback((newRole) => {
        if (role !== organisationRoles.OWNER) {
            setRole(newRole);
        }
    }, []);

    // complete own tasks
    // create reports

    return (
        <div className={"select-role"}>
            <div className={className1}
                 onClick={() => onChangeRole(organisationRoles.USER)}>
                <div className="containerImage">
                    <div className="containerRadioButton">
                        <GreenRadio
                            checked={role === organisationRoles.USER}
                            value="1"
                            name={organisationRoles.USER}
                        />
                    </div>
                    <div className="containerImg">
                        <img alt={t("user.role.user.label")}
                             className="img"
                             src={"/images/user.png"}/>
                    </div>
                    <div className="containerHeading">
                        {t("user.role.executor.singular")}
                    </div>
                    <div className="containerText">
                        <Bullets str="user.role.user.bullets.bullet"/>
                    </div>
                </div>
            </div>
            <div className={className2}
                 onClick={() => onChangeRole(organisationRoles.ORGANISATION_MANAGER)}>
                <div className="containerImage">
                    <div className="containerRadioButton">
                        <GreenRadio
                            checked={role === organisationRoles.ORGANISATION_MANAGER}
                            value="2"
                            name={organisationRoles.ORGANISATION_MANAGER}
                        />
                    </div>
                    <div className="containerImg">
                        <img
                            alt={t("user.role.organisationManager.singular")}
                            className="img"
                            src="/images/manager.png"
                        />
                    </div>
                    <div className="containerHeading">
                        {t("user.role.manager.label")}
                    </div>
                    <div className="containerText">
                        <Bullets str="user.role.organisationManager.bullets.bullet"/>
                    </div>
                </div>
            </div>
            <div className={className3}
                 onClick={() => onChangeRole(organisationRoles.ADMINISTRATOR)}>
                <div className="containerImage">
                    <div className="containerRadioButton">
                        <GreenRadio
                            checked={role === organisationRoles.ADMINISTRATOR}
                            value="2"
                            name={organisationRoles.ADMINISTRATOR}
                        />
                    </div>
                    <div className={"containerImg"}>
                        <img
                            alt={t("user.role.administrator.singular")}
                            className="img"
                            src="/images/administrator.png"
                        />
                    </div>
                    <div className="containerHeading">
                        {t("user.role.administrator.label")}
                    </div>
                    <div className="containerText">
                        <Bullets str="user.role.administrator.bullets.bullet"/>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
}

SelectOrganisationRole.propTypes = {
    role: PropTypes.string.isRequired,
    setRole: PropTypes.func.isRequired,
    error: PropTypes.string,
    showOrganisationRole: PropTypes.bool
};