import { getI18n, useTranslation } from "react-i18next";
import Icon, { ICONS } from "shared/components/Icon";
import React from "react";

export function Bullets({ str }) {

    const [t] = useTranslation();

    const renderBullets = (bullets) => {

        const mappedBullets = Array.isArray(bullets) ?
            bullets.map(({ icon, label }, index) => {
                return (
                    <li key={index} className="okcomply-role-bullet">
                        <span>
                            <Icon name={icon}/>
                        </span>
                        <span>{label}</span>
                    </li>);
            }) : [];

        return <ul className="okcomply-role-bullets">{mappedBullets}</ul>;
    };

    if (typeof str !== "string") {
        return renderBullets([]);
    }

    const bullets = [];

    for(let i=1; i < 10; i++) {

        const key = `${str}${i}`;

        const translationKey = getI18n().exists(key);

        if (!translationKey) {
            break;
        } else {
            bullets.push({ icon: ICONS.CHECK, label: t(key) });
        }
    }

    return renderBullets(bullets);
}
