import React from "react";
import UsersOverview from "./overview/UsersOverview";

export default function ManagementUsers() {
    return (
        <div className="users-flow">
            <UsersOverview />
        </div>
    );
};
