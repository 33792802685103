import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import InputMultiSelect from "shared/components/inputs/InputMultiSelect";
import Button from "shared/components/buttons/Button";
import Icon from "shared/components/Icon";
import InputSelect from "shared/components/inputs/InputSelect";
import { getTypename } from "./helpers/index";
import { useDispatch } from "react-redux";
import assignOwnersToDossierAction from "../../redux/actions/dossier/assignOwnersToDossierAction"
import assignOwnersToReportAction from "../../redux/actions/report/assignOwnersToReportAction"
import assignOwnersToTaskAction from "../../redux/actions/task/assignOwnersToTaskAction"
import assignExecutorToTaskAction from "../../redux/actions/task/assignExecutorToTaskAction"
import fetchUsersAction from "redux/actions/ownership/fetchUsersAction";
import { isValidIdentifier } from "helpers";
import { snackbarError, snackbarSuccess } from "shared/redux/actions/Snackbar";
import { TransferContext } from "./TransferOverview";

const UserResponsibilityItem = ({ responsibility, owners, updateCard, index }) => {

    const [t] = useTranslation();
    const [finalResponsibilities, setFinalResponsibilities] = useState(owners);
    const [executor, setExecutor] = useState(responsibility.executor ? responsibility.executor.id : null);
    const dispatch = useDispatch();
    const [sequenceNumber, setSequenceNumber] = useState(responsibility.sequenceNumber);
    const [users, setUsers] = useState([]);

    const { windowWidth, setSize } = React.useContext(TransferContext);

    const root = React.useRef();
    useEffect(() => {
        setSize(index, root.current.offsetHeight);
    }, [windowWidth, users]);


    const getPrefix = () => {
        let prefix = "";
        if (window.location.hostname.includes("acceptance")) {
            prefix = "acceptance.";
        }
        return prefix;
    }

    const typename = getTypename(responsibility);
    let itemNumber = responsibility.dossierNumber ? responsibility.dossierNumber :
        responsibility.reportNumber ? responsibility.reportNumber : "";

    const getItemType = () => {
        let itemType = "";
        if (typename === "Dossier") {
            itemType = "dossiers/dossier";
        } else if (typename === "Report") {
            itemType = "reports";
        } else if (typename === "Task") {
            itemType = "tasks/task";
        }
        return itemType;
    }

    const itemType = getItemType();

    const previewItem = () => {
        // window.open(`http://localhost:1337/${itemType}/${responsibility.id}/info/${responsibility.organisationId}`, '_blank'); 
        window.open(`http://${getPrefix()?.length > 0 ? getPrefix() : "my."}okcomply.com/${itemType}/${responsibility.id}/info/${responsibility.organisationId}`, '_blank');
    };

    const handleError = (error, t, dispatch) => {
        if (error?.graphQLErrors !== undefined &&
            error?.graphQLErrors[0] !== undefined &&
            typeof error.graphQLErrors[0].message === "string") {
            const errorMessage = t(error.graphQLErrors[0].message);
            dispatch(snackbarError(errorMessage));
        } else {
            dispatch(snackbarError(t("unknown.error.occurred")));
        }
    };

    const handleSuccess = (setSequenceNumber, updateCard, dispatch, t, sequenceNumber, finalResponsibilities) => {
        setSequenceNumber(sequenceNumber + 1);
        updateCard(finalResponsibilities.map((user) => user.value));
        dispatch(snackbarSuccess(t("task.update.success")));
    };

    const updateResponsibility = async () => {
        let newSequenceNumber = sequenceNumber + 1;
        const ownerValues = finalResponsibilities.map((owner) => owner.value);

        try {
            if (typename === "Dossier") {
                await dispatch(assignOwnersToDossierAction(responsibility.id, newSequenceNumber, ownerValues));
                handleSuccess(setSequenceNumber, updateCard, dispatch, t, sequenceNumber, finalResponsibilities);
            } else if (typename === "Report") {
                await dispatch(assignOwnersToReportAction(responsibility.id, newSequenceNumber, ownerValues));
                handleSuccess(setSequenceNumber, updateCard, dispatch, t, sequenceNumber, finalResponsibilities);
            } else if (typename === "Task") {
                await dispatch(assignOwnersToTaskAction(responsibility.id, newSequenceNumber, ownerValues));
                newSequenceNumber++;
                handleSuccess(setSequenceNumber, updateCard, dispatch, t, sequenceNumber, finalResponsibilities);

                await dispatch(assignExecutorToTaskAction(responsibility.id, newSequenceNumber, executor));
                handleSuccess(setSequenceNumber, updateCard, dispatch, t, newSequenceNumber, finalResponsibilities);
            }
        } catch (error) {
            handleError(error, t, dispatch);
        }
    };


    useEffect(() => {
        if (isValidIdentifier(responsibility.organisationId)) {
            dispatch(fetchUsersAction(responsibility.organisationId))
                .then(result => {
                    const { data } = result;
                    setUsers(data.users);
                })
                .catch(error => {
                    console.error("Error fetching users:", error);
                });

        }
    }, [responsibility.organisationId]);

    const onChangeOwners = (ownerIds) => {
        const userFilter = (user) =>
            ownerIds.some((ownerId) => ownerId === user.id);

        const updatedOwners = Array.isArray(users) ?
            users.filter(userFilter) : [];

        setFinalResponsibilities(updatedOwners.map((user) => { return { label: user.name + " " + user.surname, value: user.id } }));
    };

    const selectableUsersList = users.map((user) => { return { label: user.name + " " + user.surname, value: user.id } })

    return (
        <div className={"okcomply-list-item has-pointer"}>
            <div className="list-item-rows" ref={root}>
                <div className="list-item-row-single-start" style={{ alignContent: "center", flex: "1" }}>
                    <div className="list-item-icon is-flex">
                        <Icon name={responsibility.icon} />
                        <span style={{ paddingLeft: "10px", flex: "2", marginTop: "-5px" }}>
                            {itemNumber ? itemNumber + "." : ""} {responsibility.label}
                        </span>
                    </div>
                </div>

                <div style={{ width: "10px", minHeight: "8px" }}></div>

                {typename !== "Task" ? <div className="list-item-row-single has-divider" style={{ paddingLeft: "10px", flex: "3" }}>
                    <div className="user-list-item">
                        <InputMultiSelect
                            name={"owners"}
                            label={t("dossier.add.owner.plural")}
                            defaultValue={finalResponsibilities.map(user => user.value)}
                            items={selectableUsersList}
                            onChange={onChangeOwners}
                            inputId={`dossierOwners${responsibility.id}`}
                        />
                    </div>
                </div> :
                    <>
                        <div className="list-item-row-single has-divider" style={{ paddingLeft: "10px", flex: "1.5" }}>
                            <div className="user-list-item">
                                <InputMultiSelect
                                    name={"owners"}
                                    label={t("dossier.add.owner.plural")}
                                    defaultValue={finalResponsibilities.map(user => user.value)}
                                    items={selectableUsersList}
                                    onChange={onChangeOwners}
                                    inputId={`owners${responsibility.id}`}
                                />
                            </div>
                        </div>
                        {responsibility.executor ? <div className="list-item-row-single has-divider" style={{ paddingLeft: "10px", flex: "1.5" }}>
                            <div className="user-list-item">
                                <InputSelect
                                    items={selectableUsersList}
                                    label={t("task.add.executor.label")}
                                    name="executor"
                                    defaultValue={executor}
                                    onChange={({ target }) => { setExecutor(target.value); }}
                                    inputId={`executor${responsibility.id}`}
                                />
                            </div>
                        </div> : null}
                    </>
                }
                <div className="list-item-row-single has-divider" style={{ paddingLeft: "10px", display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                        <div style={{ marginBottom: '10px' }}>
                            <Button
                                outlined
                                fullWidth
                                secondary
                                onClick={previewItem}
                                data-testid={"cancelDossier"}
                                label={t("button.preview.label")}
                            />
                        </div>
                        <div>
                            <Button
                                fullWidth
                                success={true}
                                testId={"addDossier"}
                                label={t("button.save.label")}
                                onClick={updateResponsibility}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div >);
};

UserResponsibilityItem.propTypes = {
    responsibility: PropTypes.object,
    owners: PropTypes.object,
    updateCard: PropTypes.func,
    index: PropTypes.number
};

export default UserResponsibilityItem;
