import React from "react";
import AppRouter from "./AppRouter";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "redux/setup/setupStore";

import "moment/locale/nl.js";

import "./App.scss";

export default function App() {
    return (
            <Provider store={store}>
                <BrowserRouter>
                    <AppRouter/>
                </BrowserRouter>
            </Provider>
    );
}
