import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import AlertContainer from "shared/components/AlertContainer";
import Button from "shared/components/buttons/Button";

import "./style.scss";

export default function OkConfirmation({ title, message, onConfirm }) {
    const [t] = useTranslation();

    return (
        <div className="ok-confirmation">
            <AlertContainer>
                <div className="img-container">
                    <img
                        className="img"
                        src={require("../../assets/img/okki-question.png")}
                    />
                </div>
                <h3>{title}</h3>
                <div className="spacer" />
                <p>{message}</p>
                <div className="spacer-double" />
                <div className="cols">
                    <div className="col">
                        <Button
                            fullWidth
                            secondary
                            onClick={onConfirm}
                            label={t("button.close.label")}
                        />
                    </div>
                </div>
            </AlertContainer>
        </div>
    );
}

OkConfirmation.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired
};

