import client from "graphql/client";
import { loader } from "graphql.macro";
import {
    manageUserSaveInvites,
    manageUserSetLoading
} from "../../actions/manageUser/index";
const fetchInvitesQuery = loader("graphql/queries/invites/fetchInvites.graphql");

export default function fetchInvitesAction(organisationId) {
    return (dispatch) => {
        dispatch(manageUserSaveInvites([]));
        dispatch(manageUserSetLoading(true));
        return client.query({
            query: fetchInvitesQuery,
            variables: { organisationId },
            fetchPolicy: "network-only"
        }).then(result => {
            const { data } = result;
            const { invites } = data;
            dispatch(manageUserSaveInvites(invites));
            dispatch(manageUserSetLoading(false));
        }).catch(() => {
            dispatch(manageUserSetLoading(false));
        });
    };
}
