import client from "graphql/client";
import { loader } from "graphql.macro";
import mapNodesToPermissions from "containers/users/helpers/NodesToPermissions";
import { okComplyRoles } from "constants/role";
const editMembership = loader("graphql/mutations/user/editMembership.graphql");

export default function editMembershipAction(userId, organisationId) {

    return (dispatch, getState) => {

        const { manageUser } = getState();
        const { user } = manageUser;
        const { okauditChecked, okpublishChecked } = user;

        const {
            okComplyRole,
            okAuditRole,
            okPublishRole,
            organisationRole,
            writePermissions
        } = user;

        const readPermissions = okComplyRole !== okComplyRoles.EXECUTOR ? user.readPermissions: [];

        const nodes = mapNodesToPermissions(writePermissions, readPermissions);

        return client.mutate({
            mutation: editMembership,
            variables: {
                userId,
                organisationId,
                nodes,
                okComplyRole,
                okAuditRole: okauditChecked ? okAuditRole : undefined,
                okPublishRole: okpublishChecked ? okPublishRole: undefined,
                organisationRole
            }
        });
    };
}
