import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { manageUserResetUser, editMembershipAction } from "redux/actions/manageUser";
import { createNodeTree } from "helpers";
import { snackbarError, snackbarSuccess } from "shared/redux/actions/Snackbar";
import { Loading } from "components/Loading";
import { useHistory } from "react-router-dom";
import { useManageUser } from "../form/useManageUser";

import Button from "shared/components/buttons/Button";
import OkSnackbar from "shared/components/OkSnackbar";
import RequestModule from "../form/RequestModule";
import OkComply from "../form/OkComply";
import OkAudit from "../form/OkAudit";
import OkPublish from "../form/OkPublish";

import { moduleKinds, moduleRequestStatuses } from "constants/module";
import { okComplyRolesArray, okAuditRolesArray, okPublishRoleArray, okComplyRoles } from "constants/role";

export default function EditUser2({ previousStep }) {

    const [t] = useTranslation();
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const {
        onChangeReadPermissions,
        onChangeWritePermissions,
        onChangeOkComplyRole,
        onChangeOkAuditRole,
        onChangeOkPublishRole,
        readPermissions,
        writePermissions,
        okComplyRole,
        okAuditRole,
        okPublishRole,
        loading,
        okauditChecked,
        okpublishChecked
    } = useManageUser();

    const { current: organisation } = useSelector(state => state.organisation);
    const { id: organisationId, nodes, modules } = organisation;

    const initialErrorState = {
        okComplyRole: false,
        okAuditRole: false,
        okPublishRole: false,
        writePermissions: false
    };

    const [errors, setErrors] = useState(initialErrorState);

    const { id } = params; // id of users


    function checkPermission(node, currentPermissions) {
        return currentPermissions.some(({ id }) => {
            return id === node.id;
        });
    }

    const initNodeTree = useCallback((currentPermissions) => {
        const mappedNodes = [...nodes].map(node => {
            if (checkPermission(node, currentPermissions)) {
                return { ...node, checked: true };
            } else {
                return { ...node };
            }
        });
        return createNodeTree(mappedNodes);
    }, []);

    const handleSubmit = () => {

        const isValidRole = (selectedRole, roles) => {
            return roles.some(role => role === selectedRole);
        };

        const isValidPermissions = (permissions) => {
            return Array.isArray(permissions) && permissions.length > 0;
        };

        const errorState = Object.assign({}, initialErrorState);

        if (!isValidRole(okComplyRole, okComplyRolesArray)) {
            errorState.okComplyRole = true;
        }

        if (okauditChecked && !isValidRole(okAuditRole, okAuditRolesArray)) {
            errorState.okAuditRole = true;
        }

        if (okpublishChecked && !isValidRole(okPublishRole, okPublishRoleArray)) {
            errorState.okPublishRole = true;
        }
        if (!isValidPermissions(writePermissions)) {
            errorState.writePermissions = true;
        }

        if (errorState.okComplyRole ||
            errorState.okAuditRole ||
            errorState.okPublishRole ||
            errorState.writePermissions) {
            setErrors(errorState);
            return;
        }

        dispatch(editMembershipAction(id, organisationId))
            .then(() => {
                dispatch(snackbarSuccess(t("user.edit.success")));
                dispatch(manageUserResetUser());
                history.push("/users");
            })
            .catch(error => {
                if (error?.graphQLErrors !== undefined &&
                    error?.graphQLErrors[0] !== undefined &&
                    typeof error.graphQLErrors[0].message === "string") {
                    const errorMessage = t(error.graphQLErrors[0].message);
                    dispatch(snackbarError(errorMessage));
                } else {
                    dispatch(snackbarError(t("unknown.error.occurred")));
                }
            });
    };

    const onCancel = () => {
        dispatch(manageUserResetUser());
        history.push("/users");
    };

    if (loading) return <Loading />;

    const writePermissionTree = initNodeTree(writePermissions) || [];
    const readPermissionTree = initNodeTree(readPermissions) || [];
    const showReadRights = okComplyRole?.value !== okComplyRoles.EXECUTOR;

    const getStatus = (module) => {
        let status = moduleRequestStatuses.INACTIVE;
        if (module === moduleRequestStatuses.ACTIVE) {
            status = moduleRequestStatuses.ACTIVE;
        } else if (module === moduleRequestStatuses.PENDING) {
            status = moduleRequestStatuses.PENDING;
        }
        return status;
    };

    const renderPublishModule = () => {

        const status = getStatus(modules?.okPublish);

        return status === moduleRequestStatuses.ACTIVE ?
            (<div className="cols">
                <div className="col">
                    <OkPublish
                        setRole={onChangeOkPublishRole}
                        role={okPublishRole}
                        error={errors.okPublishRole} />
                </div>
            </div>) : <RequestModule module={moduleKinds.OkPublish}
                status={status} />;
    };

    const renderAuditModule = () => {

        const status = getStatus(modules?.okAudit);

        return status === moduleRequestStatuses.ACTIVE ?
            (<div className="cols">
                <div className="col">
                    <OkAudit
                        setRole={onChangeOkAuditRole}
                        role={okAuditRole}
                        error={errors.okAuditRole} />
                </div>
            </div>
            ) : <RequestModule module={moduleKinds.OkAudit}
                status={status} />;
    };

    return (
        <div className="new-user">
            <div className="cols">
                <div className="col">
                    <OkComply
                        tree={undefined}
                        setRole={onChangeOkComplyRole}
                        role={okComplyRole}
                        showReadRights={showReadRights}
                        writePermissionTree={writePermissionTree}
                        readPermissionTree={readPermissionTree}
                        onChangeReadPermissions={onChangeReadPermissions}
                        onChangeWritePermissions={onChangeWritePermissions}
                        okComplyRoleError={errors.okComplyRole}
                        writePermissionsError={errors.writePermissions} />
                </div>
            </div>
            {renderAuditModule()}
            {renderPublishModule()}
            <div className="cols">
                <div className="col">
                    <Button
                        label={t("button.save.label")}
                        onClick={handleSubmit}
                        spacedRight
                    />
                    <Button
                        label={t("button.previous.label")}
                        onClick={() => previousStep()}
                        secondary
                        spacedLeft
                    />
                    <Button
                        label={t("button.cancel.label")}
                        onClick={onCancel}
                        outlined
                        spacedRight
                    />
                </div>
            </div>
            <OkSnackbar />
        </div>
    );
}

EditUser2.propTypes = {
    previousStep: PropTypes.func
};
