export const organisationRoles = {
    USER: "USER",
    ADMINISTRATOR: "ADMINISTRATOR",
    ORGANISATION_MANAGER: "ORGANISATION_MANAGER",
    OWNER: "OWNER"
};

export const okComplyRoles = {
    EXECUTOR: "EXECUTOR",
    MANAGER: "MANAGER",
    DIRECTOR: "DIRECTOR"
};

export const okComplyRoleItems = [
    { label: "user.role.executor.label", value: okComplyRoles.EXECUTOR },
    { label: "user.role.manager.label", value: okComplyRoles.MANAGER },
    { label: "user.role.director.label", value: okComplyRoles.DIRECTOR }
];

export const organisationRoleItems = [
    { label: "user.role.user.label", value: organisationRoles.USER},
    { label: "user.role.manager.label", value: organisationRoles.ORGANISATION_MANAGER },
    { label: "user.role.administrator.label", value: organisationRoles.ADMINISTRATOR },
    { label: "user.role.owner.label", value: organisationRoles.OWNER }];

export const organisationRolesArray = [
    organisationRoles.ORGANISATION_MANAGER,
    organisationRoles.USER,
    organisationRoles.OWNER,
    organisationRoles.ADMINISTRATOR
];

export const GRANTS = {
    READ: "READ",
    WRITE: "WRITE"
};

export const okComplyRolesArray = [
    okComplyRoles.EXECUTOR,
    okComplyRoles.MANAGER,
    okComplyRoles.DIRECTOR
];

export const okPublishRoles = {
    AUTHOR: "AUTHOR",
    SERVICE: "SERVICE",
};

export const okPublishRoleArray = [
    okPublishRoles.AUTHOR,
    okPublishRoles.SERVICE
];

export const okAuditRoles = {
    AUDITOR: "AUDITOR",
    QUALITY_MANAGER: "QUALITY_MANAGER",
};

export const okAuditRolesArray = [
    okAuditRoles.AUDITOR,
    okAuditRoles.QUALITY_MANAGER
];