import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Button from "shared/components/buttons/Button";
import OrganisationCard from "components/Card";
import {
    moduleKinds,
    moduleRequestStatuses,
    moduleRequestType
} from "constants/module";
import { Bullets } from "./Bullets";

import "./style.scss";

export default function OkPublishModule({ requestModule, module}) {

    const [t] = useTranslation();

    const active = module === moduleRequestStatuses.ACTIVE;

    const renderRequestModuleButton = () => {
        return (module === null || module === moduleRequestStatuses.INACTIVE) &&
            <Button onClick={() =>
                requestModule(moduleKinds.OkPublish,
                    moduleRequestType.REQUEST)}
            label={t("organisation.module.request.label")}
            success/>;
    };

    const renderTerminateModuleButton = () => {
        return (module === moduleRequestStatuses.ACTIVE) &&
            <Button onClick={() =>
                requestModule(moduleKinds.OkPublish,
                    moduleRequestType.CANCEL)}
            label={t("organisation.module.cancel.label")}
            success/>;
    };

    return (
        <OrganisationCard heading={t("organisation.module.publish.heading")}
            subheading={t("organisation.module.publish.subheading")}
            imageUrl={require("assets/img/okcomply-bibliotheek.png")}
            module={module}
            active={active}
            selected={active}>
            <Bullets str="organisation.module.publish.bullets.bullet" />
            {renderRequestModuleButton()}
            {renderTerminateModuleButton()}
        </OrganisationCard>);
}

OkPublishModule.propTypes = {
    requestModule: PropTypes.func.isRequired
};