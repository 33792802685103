import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import InputSelect from "shared/components/inputs/InputSelect";
import { useDispatch, useSelector } from "react-redux";
import { isValidIdentifier } from "helpers";
import Button from "shared/components/buttons/Button";
import fetchUsersAction from "redux/actions/ownership/fetchUsersAction";
import OkWarning from "shared/components/OkWarning";
import inviteToOwnershipAction from "redux/actions/ownership/inviteToOwnershipAction"
import { organisationRoles } from "constants/role";
import { snackbarError, snackbarSuccess } from "shared/redux/actions/Snackbar";
import { Step, StepLabel, Stepper } from "@material-ui/core";
import { QontoConnector, ColorlibStepIcon } from "components/Step";

export default function OwnershipOverview() {

    const [t] = useTranslation();

    const dispatch = useDispatch();

    const [users, setUsers] = useState([]);
    const [confirmation, setConfirmation] = useState(false);

    const { current: organisation, membership } = useSelector(state => state.organisation);

    const { handleSubmit, errors, control, reset, getValues } = useForm();

    const steps = [
        "organisation.ownership.select.user",
        "organisation.ownership.receive.email",
        "organisation.ownership.receive.confirmation",
        "organisation.ownership.transfer.success"
    ];


    const formatUser = (user) => {
        return typeof user?.name === "string" && user.name.length > 0 &&
        typeof user?.surname === "string" && user.surname.length > 0 ?
            { value: user.id, label: user.name + " " + user.surname } :
            { value: user.id, label: user.email };
    };

    const mapToSelectItems = (users) => {
        return Array.isArray(users) ? users.map(user => formatUser(user)) : [];
    };

    useEffect(() => {
        if (isValidIdentifier(organisation?.id)) {
            dispatch(fetchUsersAction(organisation.id))
                .then(result => {
                    const { data } = result;
                    const newUsers = [];
                    for (const user of data.users) {
                        if (user.id !== membership.userId) {
                            newUsers.push(user);
                        }
                    }
                    reset(); // de-select user
                   setUsers(newUsers);
                });
        }
    }, [organisation]);


    const onSubmit = async (formData) => {

        const found = users.find(user => user.id === formData.user);
        if (found) {
            await dispatch(inviteToOwnershipAction(found.email, found.id))
                .then(() => {
                    dispatch(snackbarSuccess(t("organisation.ownership.inviteSent")));
                })
                .catch(error => {
                    if (error?.graphQLErrors !== undefined &&
                        error?.graphQLErrors[0] !== undefined &&
                        typeof error.graphQLErrors[0].message === "string") {
                        const errorMessage = t(error.graphQLErrors[0].message);
                        dispatch(snackbarError(errorMessage));
                    } else {
                        dispatch(snackbarError(t("unknown.error.occurred")));
                    }
                });
            setConfirmation(false);
        }
    };

    const hasUsers = () => {
        return Array.isArray(users) && users.length > 0;
    };

    const userIsNotAnOwner = () => {
        return membership && membership.organisation.role !== organisationRoles.OWNER;
    };

    const renderSelectUser = () => {

        if (!hasUsers()) {
            return <div>{t("organisation.ownership.users.notFound")}</div>;
        }

        return (
            <Controller
                name="user"
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                as={
                    <InputSelect
                        items={mapToSelectItems(users)}
                        label={t("button.select.what", { what: t("user.singular")})}
                        name="user"
                        error={errors.user}
                    />}
            />);
    };

    const renderSteps = () => {
        return (
            <Stepper orientation="vertical" connector={<QontoConnector/>}>
                {steps.map((step, index) => {
                    return (
                        <Step key={index} active>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>
                                {t(`organisation.ownership.step${index + 1}`)}
                                <br/>
                                {t(steps[index])}
                            </StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
        );
    };

    const validateSubmit = () => {
        const selectedUser = getValues("user");
        if (selectedUser) {
            setConfirmation(true);
        } else {
            dispatch(snackbarError(t("organisation.ownership.select.user")));
        }
    };

    const renderSubmitButton = () => {
        return hasUsers() &&
            <Button spacedRight onClick={() => validateSubmit()}
                    label={t("button.save.label")}/>;
    };

    return (
        <div className="users-page">
            <div className="users-page-header">
                <div className="title">{t("organisation.ownership.title")}</div>
                <span>&nbsp;</span>
            </div>
            <div className="page-divider"/>
            {userIsNotAnOwner() ?
                <div className="cols">
                    <div className="col">
                        <span>{t("organisation.ownership.notanowner")}</span>
                    </div>
                </div> :
                <>
                    <div className="cols">
                        <div className="col">
                            {t("organisation.ownership.subtext")}
                        </div>
                    </div>
                    <div className="spacer-double"/>
                    <div className="cols">
                        <div className="col">
                            {renderSteps()}
                        </div>
                    </div>
                    <div className="spacer-double"/>
                    <div className="cols">
                        <div className="col">
                            {renderSelectUser()}
                        </div>
                    </div>
                    <div className="page-divider"/>
                    <div className="cols">
                        <div className="col">
                            {renderSubmitButton()}
                        </div>
                    </div>
                    {confirmation &&
                    <OkWarning
                        title={t("user.ownership.confirm.title")}
                        message={t("user.ownership.confirm.message")}
                        onConfirm={handleSubmit(onSubmit)}
                        onCancel={() => setConfirmation(false)}
                    />}
                </>
            }
        </div>);

}