import React from "react";
import Sidebar from "components/Sidebar";
import TransferOverview from "./TransferOverview";

export default function ManagementTransfer() {
    return (
        <div className="users-flow">
            <TransferOverview/>
        </div>
    );
};
