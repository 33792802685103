import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import OkConfirmation from "../../shared/components/OkConfirm";
import { useTranslation } from "react-i18next";
import setOwnershipAction from "redux/actions/ownership/setOwnershipAction";
import { useDispatch } from "react-redux";
import { ownershipInviteLoadAction } from "redux/actions/ownership/ownershipInviteAction";
import { snackbarError } from "shared/redux/actions/Snackbar";
import { inviteStatuses, requireInviteNotToBeUsed } from "../requires";
import OkWarning from "shared/components/OkWarning";

import "./style.scss";

export default function TransferOwnership() {

    const [t] = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const [invite, setInvite] = useState(null);
    const [confirmed, setConfirmed] = useState(false);

    const setOwnership = async (invite) => {
        await dispatch(setOwnershipAction(invite))
            .then(() => {
                setConfirmed(true);
            }).catch(error => {
                dispatch(snackbarError("okapi.error.ownership.invite.error"));
            });
    };

    useEffect(() => {
        dispatch(ownershipInviteLoadAction(params))
            .then(result => {
                const { data } = result;
                const { success, invite } = data;
                setInvite(invite);
                if (success && invite && invite.status === inviteStatuses.SENT) {
                    setOwnership(invite);
                }
            }).catch(() => {
            dispatch(snackbarError("okapi.error.ownership.invite.error"));
        });
    }, []);

    const onConfirm = () => {
        setConfirmed(false);
        history.push("/ownership");
    };

    const inviteUsed = invite && requireInviteNotToBeUsed(invite);

    return (
        <div className="organisation-ownership">
            {confirmed &&
            <OkConfirmation
                title={t("organisation.ownership.confirmation.title")}
                message={t("organisation.ownership.confirmation.message")}
                onConfirm={() => onConfirm()}
            />}
            {inviteUsed &&
            <OkWarning title={"organisation.ownership.invite.invalid"}
                       message={"organisation.ownership.invite.invalid"}
                        onConfirm={undefined}/>
            }
        </div>
    )


}