import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import ListHeaderItem from "./ListHeaderItem/index";

import "./style.scss";

export default function ListHeader({ items }) {

    const renderItems = () => {

        return Array.isArray(items) && items.map((item, index) => {

            return (
                <ListHeaderItem
                    key={index}
                    index={index}
                    headerItem={item}
                    className="list-header-item"
                />
            );
        });
    };
    return (
        <div className="okcomply-list-header">
            <div className="list-item-rows">
                {renderItems()}
                <div className="filler"/>
            </div>
        </div>
    );
}

ListHeader.propTypes = {
    items: PropTypes.array.isRequired,
};
