import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AuditModule from "./AuditModule";
import PublishModule from "./PublishModule";
import OkComplyModule from "./OkComplyModule";
import { useDispatch, useSelector } from "react-redux";
import requestModuleAction from "redux/actions/organisation/requestModuleAction";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import { ColorlibStepIcon, QontoConnector } from "components/Step";
import { setModules } from "redux/actions/organisation";
import { snackbarError, snackbarSuccess } from "shared/redux/actions/Snackbar";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Icon, { ICONS } from "shared/components/Icon";
import { moduleRequestStatuses } from "constants/module";

import "./style.scss";


export default function ModulesOverview() {

    const [t] = useTranslation();

    const dispatch = useDispatch();

    const [expanded, setExpanded] = useState(null);

    const { current: organisation } = useSelector(state => state.organisation);

    if (!organisation) return null;

    const { id: organisationId, modules } = organisation;

    let okComplyModule = null;
    let okAuditModule = null;
    let okPublishModule = null;

    if (modules) {
        const { okComply, okAudit, okPublish } = modules;
        okComplyModule = typeof okComply !== "undefined" ? okComply : null;
        okAuditModule = typeof okAudit !== "undefined" ? okAudit : null;
        okPublishModule = typeof okPublish !== "undefined" ? okPublish : null;
    }

    const requestModule = (module, requestType) => {

        dispatch(requestModuleAction(organisationId, module, requestType))
            .then(result => {
                const { data } = result;
                const { requestModule } = data;
                const { modules } = requestModule;
                dispatch(setModules(modules));
                dispatch(snackbarSuccess(t(`organisation.module.${requestType.toLowerCase()}.sent`)));
            }).catch(error => {
                if (typeof error?.graphQLErrors[0]?.message === "string") {
                    const errorMessage = t(error.graphQLErrors[0].message);
                    dispatch(snackbarError(errorMessage));
                } else {
                    dispatch(snackbarError(t("unknown.error.occurred")));
                }
            });
    };
    const cancelSteps = [
        t("organisation.module.cancel.steps.step1"),
        t("organisation.module.cancel.steps.step2"),
        t("organisation.module.cancel.steps.step3"),
        t("organisation.module.cancel.steps.step4")
    ];

    const requestSteps = [
        t("organisation.module.request.steps.step1"),
        t("organisation.module.request.steps.step2"),
        t("organisation.module.request.steps.step3"),
        t("organisation.module.request.steps.step4")
    ];

    const renderSteps = (steps) => {
        return (
            <Stepper orientation="vertical" connector={<QontoConnector/>}>
                {steps.map((step, index) => {
                    return (
                        <Step key={index} active completed>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>
                                {t(`organisation.ownership.step${index + 1}`)}
                                <br/>
                                {t(steps[index])}
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        );
    };

    const onChange = (panel) => {
        if (typeof panel === "string" && expanded === panel) {
            setExpanded(null);
        } else {
            setExpanded(panel);
        }
    };


    const renderRequestSteps = () => {
        return ([okComplyModule, okAuditModule, okPublishModule]
            .some(module => module === moduleRequestStatuses.INACTIVE ||
                    module === moduleRequestStatuses.PENDING ||
                module === null)) &&
            (
                <Accordion expanded={expanded === "panel1"}
                    onChange={() => onChange("panel1")}>
                    <AccordionSummary expandIcon={<Icon name={ICONS.ANGLE_DOWN}/>}>
                        <div style={{ fontSize: "20px" }}>
                            {t("organisation.module.request.label")}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stepper orientation="vertical">
                            {renderSteps(requestSteps)}
                        </Stepper>
                    </AccordionDetails>
                </Accordion>);
    };

    const renderCancelSteps = () => {
        return ([okComplyModule, okAuditModule, okPublishModule]
            .some(module => module === moduleRequestStatuses.ACTIVE ||
                    module === moduleRequestStatuses.CANCELED)) &&
            (
                <Accordion expanded={expanded === "panel2"}
                    onChange={() => onChange("panel2")}>
                    <AccordionSummary expandIcon={<Icon name={ICONS.ANGLE_DOWN}/>}>
                        <div style={{ fontSize: "20px" }}>
                            {t("organisation.module.cancel.label")}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stepper orientation="vertical">
                            {renderSteps(cancelSteps)}
                        </Stepper>
                    </AccordionDetails>
                </Accordion>);
    };

    return (
        <div className="users-page">
            <div className="users-page-header">
                <div className="title">{t("organisation.module.plural")}</div>
                <span>&nbsp;</span>
            </div>
            <div className="page-divider"/>
            <div className="organisation-modules">
                <div className="cols">
                    <div className="col">
                        <OkComplyModule requestModule={requestModule}
                            module={okComplyModule}
                        />
                    </div>
                    <div className="col">
                        <AuditModule requestModule={requestModule}
                            module={okAuditModule}/>
                    </div>
                    <div className="col">
                        <PublishModule requestModule={requestModule}
                            module={okPublishModule}/>
                    </div>
                </div>
                <div className="cols">
                    <div className="col"/>
                    <div className="spacer-double"/>
                </div>
                <div className="page-divider"/>
                <div className="cols">
                    <div className="col">
                        {renderRequestSteps()}
                    </div>
                </div>
                <div className="cols">
                    <div className="col">
                        {renderCancelSteps()}
                    </div>
                </div>
            </div>
        </div>);
}