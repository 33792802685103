import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import revokeInviteAction from "redux/actions/manageUser/revokeInviteAction";
import fetchInvitesAction from "redux/actions/manageUser/fetchInvitesAction";
import { snackbarError, snackbarSuccess } from "shared/redux/actions/Snackbar";
import approveInviteAction from "redux/actions/manageUser/approveInviteAction";
import disapproveInviteAction from "redux/actions/manageUser/disapproveInviteAction";
import OkWarning from "shared/components/OkWarning";
import DotMenu from "shared/components/DotMenu";
import { inviteStatusses } from "constants/invite";


export default function InviteItem({ index, invite, organisationId }) {

    const [t] = useTranslation();

    const dispatch = useDispatch();

    const [action, setAction] = useState(null);

    const revokeInvite = (invite) => {

        const { id, sequenceNumber } = invite;

        dispatch(revokeInviteAction(organisationId, { inviteId: id, sequenceNumber }))
            .then(() => {

                const message = t("invite.revoke.success", { who: invite.sendTo });
                dispatch(snackbarSuccess(message));
                window.location.reload();

            }).catch((error) => {
            const errorMessage = typeof error.graphQLErrors !== "undefined" ?
                error.graphQLErrors[0].message :
                "invite.revoke.error";
            dispatch(snackbarError(t(errorMessage)));
        });
    };

    const approveInvite = (invite) => {

        const { id, sequenceNumber } = invite;

        dispatch(approveInviteAction(organisationId, { inviteId: id, sequenceNumber }))
            .then(() => {

                const successMessage = t("invite.approve.success", { who: invite.sendTo });
                dispatch(snackbarSuccess(successMessage));
                window.location.reload();

            }).catch((error) => {
                const errorMessage = typeof error.graphQLErrors !== "undefined" ?
                    error.graphQLErrors[0].message :
                    "invite.approve.error";
                dispatch(snackbarError(t(errorMessage)));
            }
        );
    };

    const disapproveInvite = (invite) => {
        const { id, sequenceNumber } = invite;

        dispatch(disapproveInviteAction(organisationId, { inviteId: id, sequenceNumber }))
            .then(() => {

                dispatch(fetchInvitesAction(organisationId));
                const message = t("invite.disapprove.success", { who: invite.sendTo });
                dispatch(snackbarSuccess(message));

            }).catch((error) => {

            const errorMessage = typeof error.graphQLErrors !== "undefined" ?
                error.graphQLErrors[0].message :
                "invite.disapprove.error";
            dispatch(snackbarError(t(errorMessage)));
        });
    };

    const ACTIONS = {
        REVOKE: "revoke",
        APPROVE: "approve",
        DISAPPROVE: "disapprove"
    };

    const onConfirm = () => {

        setAction(null);

        switch (action) {
            case ACTIONS.REVOKE:
                revokeInvite(invite);
                break;
            case ACTIONS.APPROVE:
                approveInvite(invite);
                break;
            case ACTIONS.DISAPPROVE:
                disapproveInvite(invite);
                break;
            default:
                return;
        }
    };

    const renderConfirm = () => {
        return (
            <OkWarning
                title={t(`user.${action}.confirm.title`)}
                message={t(`user.${action}.confirm.message`)}
                onConfirm={() => onConfirm()}
                onCancel={() => setAction(null)}
            />);
    };

    const renderDotmenu = (status) => {

        const inviteIsNotUsed = [
            inviteStatusses.NEW,
            inviteStatusses.SENT,
            inviteStatusses.PENDING].some((s) => s === status);

        const inviteIsAccepted = status === inviteStatusses.ACCEPTED;

        const menuItems = [];

        if (inviteIsNotUsed) {
            menuItems.push({ label: t("button.yes.what",
                    { what: t("button.revoke.label") }),
                action: () => setAction(ACTIONS.REVOKE) });
        }
        else if (inviteIsAccepted) {

            menuItems.push({ label: t("button.yes.what", {
                what: t("button.disapprove.label")
            }),
                action: () => setAction(ACTIONS.APPROVE) });
            menuItems.push({ label: t("button.yes.what", {
                    what: t("button.approve.label")}),
                action: () => setAction(ACTIONS.DISAPPROVE) });

        }

        return (status !== inviteStatusses.REVOKED && menuItems.length > 0) && (
            // position relative for dot menu
            <div style={{ position: "relative" }}>
                <DotMenu menuItems={menuItems}/>
            </div>
        );
    };

    const { sendTo, status, acceptedBy, permissions } = invite;
    const { okComplyRole } = permissions;

    const statusLabel = t(`invite.state.${status.toLowerCase()}.label`) +
        (status !== inviteStatusses.ACCEPTED ? "" : ` (${acceptedBy.email})`);

    return (
        <div className="okcomply-list-item"
             data-testid={`user-list-item${index}`}>
            <div className="list-item-rows">
                <div className="list-item-row-single">
                    {statusLabel}
                </div>
                <div className="list-item-row-single">
                    {sendTo}
                </div>
                <div className="list-item-row-single">
                    <div className="list-item-label">
                        {okComplyRole && t(`user.role.${okComplyRole.toLowerCase()}.label`)}
                    </div>
                </div>
                <div className="list-item-icons">
                    {renderDotmenu(status)}
                </div>
            </div>
            {action && renderConfirm()}
        </div>
    );
}

InviteItem.propTypes = {
    index: PropTypes.number.isRequired,
    invite: PropTypes.object.isRequired,
    organisationId: PropTypes.string.isRequired
};
