import produce from "immer";

import {
    ORGANISATION_SET_ORGANISATION,
    ORGANISATIONS_LOADED,
    USER_PROFILE_LOADED,
    ORGANISATION_LOADING,
    ORGANISATIONS_SET_MEMBERSHIP, ORGANISATIONS_SET_MODULES
} from "../types";

const initialState = {
    organisations: [],
    current: null,
    loading: false,
    userProfile: null,
    membership: null,
    modules: null

};

const organisationReducer = produce((draft, action) => {
    switch (action.type) {
        case ORGANISATION_SET_ORGANISATION:
            const { organisation } = action;
            draft.current = organisation;
            break;
        case ORGANISATIONS_LOADED:
            draft.organisations = action.organisations;
            break;
        case USER_PROFILE_LOADED:
            draft.userProfile = action.userProfile;
            break;
        case ORGANISATION_LOADING: {
            draft.loading = action.loading;
            break;
        }
        case ORGANISATIONS_SET_MEMBERSHIP: {
            draft.membership = action.membership;
            break;
        }
        case ORGANISATIONS_SET_MODULES: {
            if (draft.current) {
                draft.current.modules = action.modules;
            }
            break;
        }
        default:
            break;
    }
}, initialState);

export default organisationReducer;