import client from "graphql/client";
import { loader } from "graphql.macro";
const requestModuleMutation = loader("graphql/mutations/organisation/requestModule.graphql");

export default function requestModuleAction(organisationId, module, requestType) {
    return async () => {
        return client.mutate({
            mutation: requestModuleMutation,
            variables: {
                organisationId,
                module,
                requestType
            }
        })
    };
}