import isValidIdentifier from "helpers/isValidIdentifier";
import fetchUsersQuery from "graphql/queries/users/fetchUsers.graphql";

const removeUserFunction = (organisationId) => {
    return (store, {data}) => {
        try {
            const removeUser = typeof data["removeUserFromOrganisation"] !== "undefined" ?
                data["removeUserFromOrganisation"] : null;

            if (removeUser) {
                const { id } = removeUser;
                if (isValidIdentifier(id)) {
                    const data = store.readQuery({
                        query: fetchUsersQuery,
                        variables: {organisationId}
                    });

                    const { users } = data;
                    const newUsers = users.filter(user => user.id !== id);

                    store.writeQuery({
                        query: fetchUsersQuery,
                        variables: {organisationId},
                        data: {users: newUsers}
                    });
                }
            }
        } catch (e) {
        }
    };
};

export default removeUserFunction;
