import React, { Component } from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";
import classnames from "classnames";

class Checkbox extends Component {

    state = { checked: false };

    componentDidMount() {
        const { checked } = this.props;
        if(checked) {
            this.setState({checked});
        }
    }

    static getDerivedStateFromProps(nextProps) {
        const { checked } = nextProps;
        return { checked };
    }

    handleOnChange = (event) => {
        event.persist();
        const { index } = this.props;
        const {  handleOnChange } = this.props;
        const checked = this.state.checked;
        this.setState({checked});
        handleOnChange(event, index, checked);
    };

    render() {
        const { label, name, index, disabled, id, className, ...rest} = this.props;
        const newProps = omit(rest, "handleOnChange"); // not allowed on input
        const { checked } = this.state;
        const checkboxId = id || `checkbox_${index}`;
        const setClasses = classnames("is-checkradio has-background-color", className && className);

        return (
            <div className="field">
                <div className="control">
                    <input
                        id={checkboxId}
                        type="checkbox"
                        className={setClasses}
                        name={name}
                        disabled={disabled}
                        onChange={(event) => this.handleOnChange(event)}
                        checked={checked}
                        {...newProps}
                    />
                    <label className="label" htmlFor={checkboxId}>{label}</label>
                </div>
            </div>
        );
    }
}

Checkbox.defaultProps = {
    checked: false,
    name: "checkbox",
    label: ""
};

Checkbox.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    name: PropTypes.string.isRequired,
    index: PropTypes.number,
    handleOnChange: PropTypes.func,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    className: PropTypes.string
};

export default Checkbox;
