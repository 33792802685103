import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import GreenRadio from "containers/users/form/RadioButton";
import { useTranslation } from "react-i18next";
import { okAuditRoles } from "constants/role";
import { Bullets } from "containers/modules/Bullets";

export default function SelectOkAuditRole({ role, setRole }) {

    const [t] = useTranslation();

    const className1 = classnames("containerRoot",
        role === okAuditRoles.AUDITOR && "selected");

    const className2 = classnames("containerRoot",
        role === okAuditRoles.QUALITY_MANAGER && "selected");

    // auditor
    // Overview not planned inspections
    // Overview assigned inspections
    // Statistics assigned inspections
    // Execute inspections

    // quality manager
    // Overview (not planned) inspections
    // Overview assigned inspections
    // Statistics assigned inspections
    // Execute inspections

    return (
        <div className="select-role">
            <div className={className1}
                 onClick={() => setRole(okAuditRoles.AUDITOR)}>
                <div className="containerImage">
                    <div className="containerRadioButton">
                        <GreenRadio
                            checked={role === okAuditRoles.AUDITOR}
                            value="1"
                            name={okAuditRoles.AUDITOR}
                        />
                    </div>
                    <div className="containerImg">
                        <img alt={t("user.role.auditor.label")}
                             className="img"
                             src={"/images/auditor.png"} />
                    </div>
                    <div className="containerHeading">
                        {t("user.role.auditor.label")}
                    </div>
                    <div className={"containerText"}>
                        <Bullets str="user.role.auditor.bullets.bullet" />
                    </div>
                </div>
            </div>
            <div className={className2}
                 onClick={() => setRole(okAuditRoles.QUALITY_MANAGER)}>
                <div className="containerImage">
                    <div className="containerRadioButton">
                        <GreenRadio
                            checked={role === okAuditRoles.QUALITY_MANAGER}
                            value="2"
                            name={okAuditRoles.QUALITY_MANAGER}
                        />
                    </div>
                    <div className="containerImg">
                        <img
                            alt={t("user.role.qualityManager.label")}
                            className="img"
                            src="/images/quality-manager.png"
                        />
                    </div>
                    <div className="containerHeading">
                        {t("user.role.qualityManager.label")}
                    </div>
                    <div className="containerText">
                        <Bullets str="user.role.qualityManager.bullets.bullet" />
                    </div>
                </div>
            </div>
        </div>
    );
}

SelectOkAuditRole.propTypes = {
    role: PropTypes.string.isRequired,
    setRole: PropTypes.func.isRequired,
    error: PropTypes.string
};