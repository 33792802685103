import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import SelectOkAuditRole from "containers/users/form/SelectOkAuditRole";
import { manageUserSetChecked } from "redux/actions/manageUser";
import { MODULES } from "redux/reducers/manageUserReducer";
import Accordion from "components/accordion";

export default function OkAudit({ setRole, role }) {

    const dispatch = useDispatch();
    const manageUser = useSelector(state => state.manageUser);
    const { user } = manageUser;
    const { okauditChecked } = user;

    const onChecked = (event, index, checked) => {
        dispatch(manageUserSetChecked(MODULES.OKAUDIT, !checked));
    };

    return (
        <Accordion onChecked={onChecked}
                   index={2}
                   checked={okauditChecked}
                   subtext={"organisation.module.okaudit.subtext"}
                   title={"organisation.module.okaudit.title"}>
            <SelectOkAuditRole setRole={setRole} role={role}/>
        </Accordion>);

}

OkAudit.propTypes = {
    setRole: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired
};
