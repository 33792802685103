export const inviteStatuses =  {
    NEW: "NEW", // invite is new created
    SENT: "SENT", // invite is sent or resent to invited
    PENDING: "PENDING", // invite is waiting for acceptance by invited
    ACCEPTED:"ACCEPTED", // invite is accepted by invited
    REVOKED:"REVOKED", // invite is rejected by invitor
    APPROVED:"APPROVED", // invite is approved by invitor
    DISAPPROVED:"DISAPPROVED", // invite is disapproved by invitor,
    RESENT:"RESENT" // invite is resent to invited
};

export function requireInviteNotToBeUsed(invite) {
    return invite.status !== inviteStatuses.NEW &&
        invite.status !== inviteStatuses.SENT &&
        invite.status !== inviteStatuses.RESENT;
}