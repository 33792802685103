import client from "graphql/client";
import { loader } from "graphql.macro";
import { setUserProfile } from "./index";
import { snackbarError } from "shared/redux/actions/Snackbar";
import createErrorMessage from "helpers/createErrorMessage";

const fetchUser = loader("shared/graphql/queries/users/fetchUserProfile.graphql");

export default function userProfileLoad() {
    return async (dispatch) => {

        try {
            const response = await client.query({ query: fetchUser });
            const { userProfile } = response.data;
            dispatch(setUserProfile(userProfile));
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            dispatch(snackbarError(createErrorMessage(error, "organisations.load.failed")));
        }
    };
}
