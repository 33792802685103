import React from "react";
import ModulesOverview from "./modulesOverview";
import "./style.scss";

export default function ManagementModules() {
    return (
        <div className="users-flow">
            <ModulesOverview/>
        </div>
    );
};
