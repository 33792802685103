import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "shared/components/buttons/Button";
import UserList from "./UserList";
import InvitedUserList from "./InvitedUserList";
import { useHistory } from "react-router-dom";
import ListHeader from "components/ListHeader";

export default function UsersOverview() {

    const [t] = useTranslation();

    const history = useHistory();

    const onClick = () => {
        history.push("/users/new");
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div className="users-page">
            <div className="users-page-header">
                <div className="title">{t("user.plural")}</div>
                <Button
                    spacedRight={true}
                    onClick={() => onClick()}
                    success={true}
                    label={t("button.invite.label")}
                />
            </div>
            <div className="page-divider" />
            <div className="users-list">{UserList()}</div>
            <div className="invited-list">{InvitedUserList()}</div>
        </div>);

}