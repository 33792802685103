import client from "graphql/client";
import { loader } from "graphql.macro";

const assignExecutorToTaskMutation = loader("graphql/mutations/task/assignExecutorToTask.graphql");

export default function assignExecutorToTaskAction(id, sequenceNumber, executorId) {
    return () => {
        const series = { id, sequenceNumber };
        return client.mutate({
            mutation: assignExecutorToTaskMutation,
            variables: { series, executor: executorId }
        });
    };
}