import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import Button from "shared/components/buttons/Button";
import OkSnackbar from "shared/components/OkSnackbar";
import InputText from "shared/components/inputs/InputText";

import { manageUserResetUser } from "redux/actions/manageUser";
import { useManageUser } from "../form/useManageUser";
import Organisation from "../form/Organisation";
import { organisationRolesArray } from "constants/role";

export default function NewUser1({ nextStep }) {

    const [t] = useTranslation();

    const history = useHistory();

    const dispatch = useDispatch();
    const {
        onChangeOrganisationRole,
        organisationRole,
        onChangeEmail,
        email,
    } = useManageUser();

    const [invalidRole, setInvalidRole] = useState(false);

    const { register, handleSubmit, errors } = useForm();

    const onCancel = () => {
        dispatch(manageUserResetUser());
        history.push("/users");
    };

    const onSubmit = () => {
        setInvalidRole(false);
        if (!organisationRolesArray.some(role => role === organisationRole)) {
            setInvalidRole(true);
        } else {
            nextStep();
        }
    };

    return (
        <div className="new-user">
                <div className="cols">
                    <div className="col">
                        <InputText
                            label={t("user.email.label")}
                            name="email"
                            defaultValue={email}
                            inputRef={register({ required: true })}
                            error={errors?.email}
                            onChange={onChangeEmail}
                        />
                    </div>
                </div>
                <div className="cols">
                    <div className="col">
                        <Organisation
                            showOrganisationRole={false}
                            setRole={onChangeOrganisationRole}
                            role={organisationRole}
                            error={invalidRole}/>
                    </div>
                </div>
            <div className="cols">
                <div className="col">
                    <Button label={t("button.next.label")}
                            onClick={handleSubmit(onSubmit)}
                            success
                            spacedRight
                    />
                    <Button label={t("button.cancel.label")}
                            onClick={onCancel}
                            outlined
                            spacedRight
                    />
                </div>
            </div>
            <OkSnackbar/>
        </div>
    );
}

NewUser1.propTypes = {
    nextStep: PropTypes.func.isRequired
};