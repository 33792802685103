import client  from "graphql/client";
import { loader } from "graphql.macro";
import mapPermissionsToNodes from "containers/users/helpers/PermissionsToNodes";
import { manageUserSaveUser, manageUserSetLoading } from "./index";
const fetchUserWithMembership = loader("graphql/queries/users/fetchUserWithMembership.graphql");

export default function fetchUserWithMemberShipAction(userId, organisationId) {
    return (dispatch) => {

      dispatch(manageUserSetLoading(true));

        client.query({
            query: fetchUserWithMembership,
            variables: { userId, organisationId },
            fetchPolicy: "network-only"
        }).then(response => {

            const { data } = response;
            const { user } = data;
            const { id, email, membership } = user;

            const {
                nodes,
                okComply,
                okAudit,
                okPublish,
                organisation
            } = membership;

            const readPermissions = mapPermissionsToNodes(nodes, "READ");
            const writePermissions = mapPermissionsToNodes(nodes, "WRITE");

            dispatch(manageUserSaveUser({
                id,
                email,
                okComplyRole: okComply.role,
                organisationRole: organisation.role,
                okAuditRole: okAudit?.role,
                okPublishRole: okPublish?.role,
                writePermissions,
                readPermissions,
                okcomplyChecked: true,
                organisationChecked: true,
                okpublishChecked: !!okPublish?.role,
                okauditChecked: !!okAudit?.role

            }));

            dispatch(manageUserSetLoading(false));
        }).catch(() => {
            dispatch(manageUserSetLoading(false));
        });
    };
}