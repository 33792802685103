export const inviteStatusses = {
    NEW: "NEW",
    SENT: "SENT",
    PENDING: "PENDING",
    ACCEPTED: "ACCEPTED",
    REVOKED: "REVOKED",
    DISAPPROVED: "DISAPPROVED",
    APPROVED: "APPROVED"
}

export const inviteStatussesArray = [
    inviteStatusses.NEW,
    inviteStatusses.SENT,
    inviteStatusses.PENDING,
    inviteStatusses.ACCEPTED,
    inviteStatusses.REVOKED,
    inviteStatusses.APPROVED,
    inviteStatusses.DISAPPROVED
];