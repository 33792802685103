import { combineReducers } from "redux";
import organisationReducer from "../reducers/organisationReducer";
import manageUserReducer from "../reducers/manageUserReducer";
import transferReducer from "../reducers/transferReducer";
import modulesReducer from "../reducers/modulesReducer";
import snackbarReducer from "../../shared/redux/reducers/SnackbarReducer";

export default combineReducers({
    organisation: organisationReducer,
    manageUser: manageUserReducer,
    transferReducer: transferReducer,
    modules: modulesReducer,
    snackbar: snackbarReducer,
});