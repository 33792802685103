import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";
import { ICONS } from "shared/components/Icon";

import classNames from "classnames";
import Icon from "../Icon";

import "./style.scss";

export const MODULE_FILTERS = {
    PUBLISH: "okPublish",
    AUDIT: "okAudit"
};

export const moduleRequestStatuses = {
    ACTIVE: "ACTIVE", // request approved, module is active
    INACTIVE: "INACTIVE"
};

const urlParams = new URLSearchParams(window.location.search);
const organisationId = urlParams.get("organisationId");

const OrganisationSelector = ({
    organisations,
    filterModule,
    onOrganisationChanged
}) => {


    const { current } = useSelector(state => state.organisation);

    const [show, setShown] = useState(null);
    const menuClasses = classNames(
        "organisation-menu",
        show !== null ? show ? "show" : "hide" : ""
    );

    useEffect(() => {

        if (organisationId && organisationId.length > 0) {
            window.history.pushState({}, document.title, window.location.pathname);
            const getIndex = () => {
                if (organisations?.length > 0) {
                    let index = 0;
                    for (const orgIndex in organisations) {
                        const org = organisations[orgIndex];
                        console.log("org", org);
                        if (org.id === organisationId) {
                            return index;
                        }
                        index++;
                    }
                }
                return 0;
            };

            onOrganisationChanged(getIndex());

        }
    }, [organisations]);

    return (
        <>
            <a
                className="organisation-menu-button"
                tabIndex="-1"
                onBlur={() => setShown(false)}
                onClick={() => setShown(true)}
            >
                <div>
                    <Icon
                        name={ICONS.ORGANISATION}
                        spacedRight
                    />
                    <span>{current?.label}</span>
                </div>
                <Icon spacedRight name={ICONS.DOWN} />
            </a>
            <div className={menuClasses}>
                {organisations.map((item, index) => {
                    // check if we should filter the list based on modules or display
                    // everything
                    if (!filterModule || (item.modules && item.modules[filterModule] &&
                        item.modules[filterModule] === moduleRequestStatuses.ACTIVE)) {
                        return (
                            <span
                                index={index}
                                key={index}
                                onMouseDown={() => onOrganisationChanged(index)}
                            >
                                {item.label}
                            </span>
                        );
                    } else {
                        return null;
                    }
                }
                )}
            </div>
        </>
    );
};

export default OrganisationSelector;

OrganisationSelector.propTypes = {
    organisations: PropTypes.array.isRequired,
    onOrganisationChanged: PropTypes.func.isRequired,
    filterModule: PropTypes.string
};
