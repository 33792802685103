import React from "react";
import PropTypes from "prop-types";
import Accordion from "components/accordion";
import SelectOrganisationRole from "containers/users/form/SelectOrganisationRole";

export default function Organisation({ setRole, role, showOrganisationRole = true }) {

    return (
        <Accordion checked={true}
               index={0}
               subtext={"organisation.module.organisation.subtext"}
               title={"organisation.module.organisation.title"}>
            <SelectOrganisationRole setRole={setRole} role={role}
                                    showOrganisationRole={showOrganisationRole}/>
        </Accordion>);

}

Organisation.propTypes = {
    setRole: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired,
    showOrganisationRole: PropTypes.bool
};