import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Button from "shared/components/buttons/Button";
import OrganisationCard from "components/Card";
import { moduleKinds, moduleRequestStatuses, moduleRequestType } from "constants/module";
import "./style.scss";
import { Bullets } from "./Bullets";

export default function AuditModule({ requestModule, module }) {

    const [t] = useTranslation();

    const active = module === moduleRequestStatuses.ACTIVE;

    const renderRequestModuleButton = () => {
        return (module === null || module === moduleRequestStatuses.INACTIVE) &&
            <Button onClick={() =>
                requestModule(moduleKinds.OkAudit,
                    moduleRequestType.REQUEST)}
                    label={t("organisation.module.request.label")}
                    success/>
    };

    const renderTerminateModuleButton = () => {
        return (module === moduleRequestStatuses.ACTIVE) &&
            <Button onClick={() =>
                requestModule(moduleKinds.OkAudit,
                    moduleRequestType.CANCEL)}
                    label={t("organisation.module.cancel.label")}
                    success/>
    };

    return (
        <OrganisationCard heading={t("organisation.module.audit.heading")}
                          subheading={t("organisation.module.audit.subheading")}
                          imageUrl={require("assets/img/okcomply-audit.png")}
                          active={active}
                          module={module}
                          selected={active}>
            <Bullets str="organisation.module.audit.bullets.bullet"/>
            {renderRequestModuleButton()}
            {renderTerminateModuleButton()}
        </OrganisationCard>
    );

}

AuditModule.propTypes = {
    requestModule: PropTypes.func.isRequired
};