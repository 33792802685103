import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import classnames from "classnames"
import { moduleRequestStatuses  } from "constants/module";

export default function OrganisationCard({ heading, subheading, imageUrl, children, selected, module }) {

    const [t] = useTranslation();

    const className = classnames("container-root", selected && "selected");

    const renderActive = () => {
        if (module === moduleRequestStatuses.ACTIVE ||
            module === moduleRequestStatuses.PENDING ||
            module === moduleRequestStatuses.CANCELED) {
            const translationKey = t(`organisation.module.request.status.${module.toLowerCase()}`);
            return (
                <span className="module-active"
                         title={translationKey}>
                    {translationKey}
                </span>);
        }
    };

    return (
        <div className="container-card">
            <div className={className}>
                {renderActive()}
                <div className="container-image">
                    <div className="container-img">
                        <img alt={""} src={imageUrl}/>
                    </div>
                </div>
                <div className="container-info">
                    <div className="container-heading">
                        {heading}
                    </div>
                    <div className="container-subheading">
                        {subheading}
                    </div>
                    <div className="container-text">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

OrganisationCard.propTypes = {
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    imageUrl: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    selected: PropTypes.bool
};