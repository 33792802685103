import client from "graphql/client";
import { loader } from "graphql.macro";

const assignOwnersToReportMutation = loader("graphql/mutations/report/assignOwnersToReport.graphql");

export default function assignOwnersToDossierAction(id, sequenceNumber, ownerIds) {
    return () => {
        const series = { id, sequenceNumber };
        return client.mutate({
            mutation: assignOwnersToReportMutation,
            variables: { series, owners: ownerIds }
        });
    };
}