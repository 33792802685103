import produce from "immer";

import {
    TRANSFER_RESPONSIBILITIES_SAVE,
    TRANSFER_RESPONSIBILITIES_REMOVE,
} from "../types/transfer";

import { ICONS } from "shared/components/Icon";

const initialState = {
    responsibilities: [],
    organisationMembers: [],
    loading: false
};

const transerReducer = produce((draft, action) => {

    switch (action.type) {
        case TRANSFER_RESPONSIBILITIES_SAVE: {
            if (action.responsibilities === null) {
                draft.responsibilities = [];
                return;
            }
            const dossiers = action.responsibilities.dossiers.map(dossier => ({
                ...dossier,
                icon: ICONS.DOSSIER,
                owners: dossier.owners
            }));
            const reports = action.responsibilities.reports.map(report => ({
                ...report,
                icon: ICONS.REPORT,
                owners: report.owners
            }));
            const tasks = action.responsibilities.tasks.map(task => ({
                ...task,
                icon: ICONS.CHECK_DOUBLE,
                owners: task.owners
            }));
            draft.responsibilities = [...dossiers, ...reports, ...tasks];
            break;
        }
        case TRANSFER_RESPONSIBILITIES_REMOVE: {
            if (!action.responsibilityId) {
                break;
            }
            draft.responsibilities = draft.responsibilities.filter(item => item.id !== action.responsibilityId);
            break;
        }
        
        default:
            return;
    }
}, initialState);


export default transerReducer;