import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import GreenRadio from "containers/users/form/RadioButton";
import { useTranslation } from "react-i18next";
import { okPublishRoles } from "constants/role";
import { Bullets } from "containers/modules/Bullets";

export default function SelectOkAuditRole({ role, setRole }) {

    const [t] = useTranslation();

    const className1 = classnames("containerRoot",
        role === okPublishRoles.AUTHOR && "selected");

    const className2 = classnames("containerRoot",
        role === okPublishRoles.SERVICE && "selected");

    // Service
    // Overview consumer
    // Invite consumer

    // Service
    // t("user.role.service.singular")
    // Create books
    // t("user.role.service.books.create")
    // Modify books
    // t("user.role.service.books.modify")
    // Remove books
    // t("user.role.service.books.remove")
    // Statistics books
    // t("user.role.service.books.statistics")

    return (
        <div className={"select-role"}>
            <div className={className2}
                 onClick={() => setRole(okPublishRoles.SERVICE)}>
                <div className={"containerImage"}>
                    <div className={"containerRadioButton"}>
                        <GreenRadio
                            checked={role === okPublishRoles.SERVICE}
                            value="1"
                            name={okPublishRoles.SERVICE}
                        />
                    </div>
                    <div className={"containerImg"}>
                        <img
                            alt={t("user.role.service.label")}
                            className={"img"}
                            src={"/images/service-employee.png"}
                        />
                    </div>
                    <div className={"containerHeading"}>
                        {t("user.role.service.label")}
                    </div>
                    <div className={"containerText"}>
                        <Bullets str="user.role.service.bullets.bullet" />
                    </div>
                </div>
            </div>
            <div className={className1}
                 onClick={() => setRole(okPublishRoles.AUTHOR)}>
                <div className={"containerImage"}>
                    <div className={"containerRadioButton"}>
                        <GreenRadio
                            checked={role === okPublishRoles.AUTHOR}
                            value="2"
                            name={okPublishRoles.AUTHOR}
                        />
                    </div>
                    <div className={"containerImg"}>
                        <img alt={t("user.role.author.label")}
                             className={"img"}
                             src={"/images/author.png"}/>
                    </div>
                    <div className={"containerHeading"}>
                        {t("user.role.author.label")}
                    </div>
                    <div className={"containerText"}>
                        <Bullets str="user.role.author.bullets.bullet" />
                    </div>
                </div>
            </div>
        </div>
    );
}

SelectOkAuditRole.propTypes = {
    setRole: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired,
    error: PropTypes.string
};