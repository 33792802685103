import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import GreenRadio from "containers/users/form/RadioButton";
import { useTranslation } from "react-i18next";
import { okComplyRoles } from "constants/role";
import { Bullets } from "containers/modules/Bullets";

export default function SelectOkComplyRole({ role, setRole, error }) {

    const [t] = useTranslation();

    const className = classnames("select-role", error && "error");

    const className1 = classnames("containerRoot",
        role === okComplyRoles.EXECUTOR && "selected");

    const className2 = classnames("containerRoot",
        role === okComplyRoles.MANAGER && "selected");

    const className3 = classnames("containerRoot",
        role === okComplyRoles.DIRECTOR && "selected");

    // executor bullets
    // complete your own tasks
    // create reports from tasks

    // manager bullets
    // all operational authorizations
    // Responsible for tasks, dossiers and reports
    // Approving your own dossiers' en reports
    // Schedule reports
    // Create dossiers
    // Finishing concept dossiers

    // administrator bullets
    // All operational authorizations
    // All tactical authorizations
    // Adding new users

    return (
        <div className={className}>
            <div className={className1}
                 onClick={() => setRole(okComplyRoles.EXECUTOR)}>
                <div className="containerImage">
                    <div className="containerRadioButton">
                        <GreenRadio
                            checked={role === okComplyRoles.EXECUTOR}
                            value="1"
                            name={okComplyRoles.EXECUTOR}
                        />
                    </div>
                    <div className="containerImg">
                        <img alt={t("user.role.executor.label")}
                             className="img"
                             src="/images/user.png"/>
                    </div>
                    <div className="containerHeading">
                        {t("user.role.executor.label")}
                    </div>
                    <div className="containerText">
                        <Bullets str="user.role.executor.bullets.bullet"/>
                    </div>
                </div>
            </div>
            <div className={className2}
                 onClick={() => setRole(okComplyRoles.MANAGER)}>
                <div className="containerImage">
                    <div className="containerRadioButton">
                        <GreenRadio
                            checked={role === okComplyRoles.MANAGER}
                            value="2"
                            name={okComplyRoles.MANAGER}
                        />
                    </div>
                    <div className="containerImg">
                        <img
                            alt={t("user.role.manager.label")}
                            className="img"
                            src={"/images/manager_ok.png"}
                        />
                    </div>
                    <div className="containerHeading">
                        {t("user.role.manager.label")}
                    </div>
                    <div className="containerText">
                        <Bullets str="user.role.manager.bullets.bullet"/>
                    </div>
                </div>
            </div>
            <div className={className3}
                 onClick={() => setRole(okComplyRoles.DIRECTOR)}>
                <div className="containerImage">
                    <div className="containerRadioButton">
                        <GreenRadio
                            checked={role === okComplyRoles.DIRECTOR}
                            value="3"
                            name={okComplyRoles.DIRECTOR}
                        />
                    </div>
                    <div className="containerImg">
                        <img alt={t("user.role.director.label")}
                             className="img"
                             src="/images/riskmanager.png"/>
                    </div>
                    <div className="containerHeading">
                        {t("user.role.director.label")}
                    </div>
                    <div className="containerText">
                        <Bullets str="user.role.director.bullets.bullet" />
                    </div>
                </div>
            </div>
        </div>
    );
}

SelectOkComplyRole.propTypes = {
    role: PropTypes.string.isRequired,
    setRole: PropTypes.func.isRequired,
    error: PropTypes.string
};