export function setNodeParents(root, nodes) {
    const nodeIds = {};
    nodes.forEach((node) => {
        const { id, path } = node;
        nodeIds[id] = true;
        if (path.length !== 0) {
            node.parentId = path[path.length - 1];
        }
    });
    // Fix all orphaned nodes
    nodes.forEach((node) => {
        if (node.parentId &&
            !nodeIds[node.parentId]) {
            node.parentId = root.id;
        }
    });
}