import { isValidIdentifier } from "helpers";
import fetchInvitesQuery from "graphql/queries/invites/fetchInvites.graphql";

const approveInviteFunction = (organisationId) => {
    return (store, {data}) => {
        try {
            const approveInvite = typeof data["approveInvite"] !== "undefined" ?
                data["approveInvite"] : null;

            if (approveInvite) {
                const { id } = approveInvite;
                if (isValidIdentifier(id)) {
                    const data = store.readQuery({
                        query: fetchInvitesQuery,
                        variables: {organisationId}
                    });

                    const { invites } = data;
                    const updatedInvites = invites.map((invite) => {
                        if (invite.id === id) {
                            return approveInvite;
                        }
                        return invite;
                    });

                    store.writeQuery({
                        query: fetchInvitesQuery,
                        data: {invites: updatedInvites}
                    });
                }
            }
        } catch (error) {
        }
    };
};

export default approveInviteFunction;
