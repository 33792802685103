import {
    ORGANISATION_SET_ORGANISATION,
    ORGANISATIONS_LOADED,
    USER_PROFILE_LOADED,
    ORGANISATION_LOADING,
    ORGANISATIONS_SET_MEMBERSHIP,
    ORGANISATIONS_SET_MODULES
} from "../../types";

// todo: prefix actions

export function setUserProfile(userProfile) {
    return {
        type: USER_PROFILE_LOADED,
        userProfile
    };
}

export function setOrganisation(organisation) {
    return {
        type: ORGANISATION_SET_ORGANISATION,
        organisation
    };
}

export function organisationsLoaded(organisations) {
    return {
        type: ORGANISATIONS_LOADED,
        organisations
    };
}

export function setLoading(loading) {
    return {
        type: ORGANISATION_LOADING,
        loading
    };
}

export function setMembership(membership) {
    return {
        type: ORGANISATIONS_SET_MEMBERSHIP,
        membership
    };
}

export function setModules(modules) {
    return {
        type: ORGANISATIONS_SET_MODULES,
        modules
    };
}