import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "components/Loading";
import DotMenu from "shared/components/DotMenu";
import OkWarning from "shared/components/OkWarning";
import { fetchUsersAction, removeUserAction } from "redux/actions/manageUser";
import { snackbarError, snackbarSuccess } from "shared/redux/actions/Snackbar";
import { useHistory } from "react-router-dom";

export default function UserItem({ organisationId, index, user }) {

    const { t, ready } = useTranslation();

    const dispatch = useDispatch();

    const history = useHistory();

    const [confirmation, setConfirmation] = useState(false);
    const organisationReducer = useSelector(state => state.organisation);
    const { userProfile } = organisationReducer;
    const { id: currentUserId } = userProfile;
    const { membership } = user;
    const { okComply, okAudit, okPublish } = membership;

    const { email, name, surname, id } = user;
    const isCurrentUser = id === currentUserId;

    if (!ready || !currentUserId) return <Loading />;

    const onDelete = () => {

        const { id: userId, email } = user;
        dispatch(removeUserAction(organisationId, { userId }))
            .then(() => {
                const message = `${t("user.singular")} ${email} ${t("user.delete.success")}`;
                dispatch(snackbarSuccess(message));
                dispatch(fetchUsersAction(organisationId));

            }).catch(error => {
                const message = typeof error.graphQLErrors !== "undefined" ?
                    error.graphQLErrors[0].message : "user.delete.error";
                dispatch(snackbarError(t(message)));
            });
    };

    const onEdit = () => {
        history.push(`/users/${id}/edit`);
    };

    return (
        <div className="okcomply-list-item"
            data-testid={`user-list-item${index}`}>
            <div className="list-item-rows">
                <div className="list-item-row-single wide">{email}</div>
                <div className="list-item-row-single">
                    {surname}{(surname && name) ? ", " : ""}{name}
                </div>
                <div className="list-item-row-single">
                    <div className="list-item-label">
                        {t("user.role." + okComply.role.toLowerCase() + ".label")}
                    </div>
                </div>
                <div className="list-item-row-single">
                    <div className="list-item-label">
                        {okPublish ? t("user.role." + okPublish.role.toLowerCase() + ".label") :
                            t("user.role.noRole.label")
                        }
                    </div>
                </div>
                <div className="list-item-row-single">
                    <div className="list-item-label">
                        {okAudit ? t("user.role." + okAudit.role.toLowerCase() + ".label") :
                            t("user.role.noRole.label")
                        }
                    </div>
                </div>
                <div className="list-item-icons">
                    <div style={{ position: "relative" }}>
                        <DotMenu menuItems={[
                            { label: t("dotMenu.edit.label"), action: () => onEdit() },
                            { label: t("dotMenu.delete.label"), action: () => setConfirmation(true) },
                        ]} />
                    </div>
                </div>
                {confirmation &&
                    <OkWarning
                        title={t("user.remove.confirm.title")}
                        message={t("user.remove.confirm.message")}
                        onConfirm={() => onDelete()}
                        onCancel={() => setConfirmation(false)}
                    />}
            </div>
        </div>);
}

UserItem.propTypes = {
    index: PropTypes.number.isRequired,
    organisationId: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired
};