import isUndefined from "lodash/isUndefined";

const getTypename = (item) => {
    if (!isUndefined(item)) {
        if (item && !isUndefined(item["__typename"])) {
            const typename = item["__typename"];
            if (typename === "ReportType") return "Report";
            if (typename === "DossierType") return "Dossier";
            if (typename === "TaskType") return "Task";
        }
    }
    return "";
};
export { getTypename };