import React, { useState } from "react";
import PropTypes from "prop-types";
import Checkbox from "components/Checkbox";
import Icon, { ICONS } from "shared/components/Icon";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import "./style.scss";

export default function Accordion(props) {

    const { checked, onChecked, title, subtext, children, index, className, open } = props;

    const [t] = useTranslation();

    const defaultActive = typeof open !== "undefined" ? open: true;

    const [active, setActive] = useState(defaultActive);

    const accordionClass = classnames("accordion", className && className);
    const introClasses = classnames("accordion-intro", className && className);
    const headerClass = classnames("accordion-header", className && className);

    const setClasses = classnames("panel",
        className && className,
        active && "active");


    return (
        <div className={accordionClass}>
            <div className={headerClass}>
                {onChecked &&
                <Checkbox
                    index={index}
                    checked={checked}
                    handleOnChange={onChecked} />}
                <div className={introClasses}>
                    <span className="accordion-title">
                        {t(title)}
                    </span>
                    <span className="accordion-subtext">
                        {t(subtext)}
                    </span>
                </div>
                <span className="icon" onClick={() => setActive(!active)}>
                    <Icon name={active ? ICONS.ANGLE_UP: ICONS.ANGLE_DOWN} />
                </span>
            </div>
            <div className={setClasses}>
                <div className="cols">
                    <div className="col">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

Accordion.propTypes = {
    checked: PropTypes.bool,
    onChecked: PropTypes.func,
    title: PropTypes.string,
    subtext: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
    index: PropTypes.number.isRequired,
    className: PropTypes.string,
    open: PropTypes.bool
};