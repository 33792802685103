const browserLanguage = navigator.language || navigator.userLanguage; 


export const i18nConfig = {
    react: {
        useSuspense: false //   <---- this will do the magic
    },
    interpolation: {
        format: function (value, format) {
            if (!(typeof value === "string")) return value;
            if (format === "uppercase") return value[0].toUpperCase() + value.slice(1);
            if (format === "lowercase") return value[0].toLowerCase() + value.slice(1);
            else return value;
        },
        // React already does escaping.
        escapeValue: false
    },
    nl: ["OKComply%20NL"],
    preload: ["nl"],
    defaultNS: "OKcomply%20NL",
    ns: "OKcomply%20NL",
    lng: browserLanguage,
    fallbackLng: "en",
    load: "all",
    backend: {
        projectId: "03653494-641d-43ce-9bb8-1e25477cf814",
        referenceLng: "nl",
        version: "latest",
    },
    debug: false
};