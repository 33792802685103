import { setOrganisation } from "./index";

// vervangen door call fetchOrganisation
export default function organisationSetByIndex(index) {
    return async (dispatch, getState) => {
        const { organisations } = getState().organisation;
        if (typeof organisations[index] !== "undefined") {
           dispatch(setOrganisation(organisations[index]));
        }
    }
} 