import client from "graphql/client";
import removeUserFunction from "./removeUserFunction";
import { loader } from "graphql.macro";
const removeUserFromOrganisation = loader("graphql/mutations/user/removeUserFromOrganisation.graphql");

export default function removeUserAction(organisationId, state) {
    return () => {
        const { userId } = state;
        return client.mutate({
            mutation: removeUserFromOrganisation,
            variables: { organisationId, userId},
            update: removeUserFunction(organisationId)
        });
    };
}
