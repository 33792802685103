import produce from "immer";

import {
    MODULES_REQUEST_MODULE
} from "../types/modules";

const initialState = {
    okComply: true,
    okAudit: false,
    okPublish: false,
    okComplyRequest: false,
    okAuditRequest: false,
    okPublishRequest: false,
    loading: false
};

export const MODULES = {
    OKCOMPLY: "OKCOMPLY",
    OKAUDIT: "OKAUDIT",
    OKPUBLISH: "OKPUBLISH",
    ORGANISATION: "ORGANISATION"
};

const modulesReducer = produce((draft, action) => {

    switch (action.type) {
        case MODULES_REQUEST_MODULE: {

            const { module } = action;
            switch(module) {
                case MODULES.OKAUDIT:
                    draft.okAuditRequest = true;
                    break;
                case MODULES.OKPUBLISH:
                    draft.okAuditRequest = true;
                    break;
                default:
                    return;
            }
            break;
        }
        default:
            return;
    }
}, initialState);


export default modulesReducer;
