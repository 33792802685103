import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Steps from "shared/components/Steps";
import { useHistory } from "react-router-dom";
import NewUser1 from "./NewUser1";
import NewUser2 from "./NewUser2";

export default function NewUserWithSteps() {

    const [t] = useTranslation();
    const history = useHistory();
    const [currentStep, setStep] = useState(0); // select the current step
    const [stepsCompleted, setStepCompleted] = useState([
        false, false
    ]);

    const setNextStep = () => {
        const nextStep = currentStep + 1;
        const nextCompletedSteps = [...stepsCompleted];
        nextCompletedSteps[currentStep] = true;
        setStepCompleted(nextCompletedSteps);
        setStep(nextStep);
    };

    const setPreviousStep = () => {
        const nextStep = currentStep - 1;
        if (nextStep >= 0) {
            setStep(nextStep);
        } else {
            history.push("/users");
        }
    };

    const steps = [
        {
            label: t("user.role.general.plural"),
            component: (
                <NewUser1 nextStep={setNextStep} />
            )
        },
        {
            label: t("user.role.specific.plural"),
            component: (
                <NewUser2 previousStep={setPreviousStep} />
            )
        }
    ];

    return (
        <div className="onboarding-setup">
            <Steps
                title={t("add.new.user")}
                showLogo
                steps={steps}
                stepsCompleted={stepsCompleted}
                onStep={setStep}
                currentStep={currentStep}
                onBack={setPreviousStep}
            />
        </div>
    );
};
