import client from "graphql/client";
import { loader } from "graphql.macro";

const assignOwnersToTaskMutation = loader("graphql/mutations/task/assignOwnersToTask.graphql");

export default function assignOwnersToTaskAction(id, sequenceNumber, ownerIds) {
    return () => {
        const series = { id, sequenceNumber };
        return client.mutate({
            mutation: assignOwnersToTaskMutation,
            variables: { series, owners: ownerIds }
        });
    };
}