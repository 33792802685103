import client from "graphql/client";
import { loader } from "graphql.macro";
import createErrorMessage from "helpers/createErrorMessage";
import { snackbarError } from "shared/redux/actions/Snackbar";
import { organisationsLoaded, setMembership } from "./index";
import organisationLoad from "redux/actions/organisation/organisationLoad";
import { fetchMembership } from "../index";
const fetchOrganisations = loader("shared/graphql/queries/organisation/fetchOrganisations.graphql");

export default function organisationLoadAll() {
    return async (dispatch) => {
        try {

            const response = await client.query({ query: fetchOrganisations });
            const { organisations } = response.data;
            dispatch(organisationsLoaded(organisations));

            if (Array.isArray(organisations) && organisations.length > 0) {
                const organisation = organisations[0];

                dispatch(organisationLoad(organisation.id));

                await dispatch(fetchMembership(organisation.id))
                    .then((membership) => {
                        dispatch(setMembership(membership));
                    });
            }

        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            dispatch(snackbarError(createErrorMessage(error, "organisations.load.failed")));
        }
    };
}
