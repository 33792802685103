import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Steps from "shared/components/Steps";
import {
    fetchUserWithMemberShipAction,
    manageUserResetUser
} from "redux/actions/manageUser";
import { isValidIdentifier } from "helpers";
import EditUser1 from "./EditUser1";
import EditUser2 from "./EditUser2";

export default function EditUserWithSteps() {

    const [t] = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();

    const [currentStep, setStep] = useState(0); // select the current step
    const [stepsCompleted, setStepCompleted] = useState([
        false, false
    ]);

    const organisationSelector = useSelector(state => state.organisation);
    const { current: organisation } = organisationSelector;
    const organisationId = organisation?.id;

    const { id } = params; // id of user

    useEffect(() => {
        dispatch(manageUserResetUser());
        if (isValidIdentifier(id) && isValidIdentifier(organisationId)) {
            dispatch(fetchUserWithMemberShipAction(id, organisationId));
        }
    }, [id, organisationId, dispatch]);

    const setNextStep = () => {
        const nextStep = currentStep + 1;
        const nextCompletedSteps = [...stepsCompleted];
        nextCompletedSteps[currentStep] = true;
        setStepCompleted(nextCompletedSteps);
        setStep(nextStep);
    };

    const setPreviousStep = () => {
        const nextStep = currentStep - 1;
        if (nextStep >= 0) {
            setStep(nextStep);
        } else {
            history.push("/users");
        }
    };

    const steps = [
        {
            label: t("user.role.general.plural"),
            component: (
                <EditUser1 nextStep={setNextStep} />
            )
        },
        {
            label: t("user.role.specific.plural"),
            component: (
                <EditUser2 previousStep={setPreviousStep} />
            )
        }
    ];

    if (!id || !organisationId) return null;

    return (
        <div className="onboarding-setup">
            <Steps
                title={t("edit.user")}
                showLogo
                steps={steps}
                stepsCompleted={stepsCompleted}
                onStep={setStep}
                currentStep={currentStep}
                onBack={setPreviousStep}
            />
        </div>
    );
};
